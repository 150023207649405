angular.module("App")
.service("EmailService", ['$timeout', '$http', function ($timeout, $http){
    function EmailFunction(){
        this.structure = []
    }

    EmailFunction.prototype.groupingOrderStructure = function(order){
        var self = this;
        var structure = self.structure;

        for(var i in order){
            var item = order[i];
            if (item == null) continue;
            if(item.current === undefined ){
                switch( typeof item ){
                    case 'object':
                        self.groupingOrderStructure(item);
                        break;
                    case 'string':
                        structure.push(i + ': ' + item);
                        break;
                }
            }else{
                switch( typeof item.current ){
                    case 'boolean':
                        if(item.current === true){
                            structure.push(item.label + ': 1шт.');
                        }
                        break;
                    case 'string':
                        structure.push(item.label + ': ' + item.current);
                        break;
                    case 'object':
                        if(item.current.quantity){
                            structure.push(item.label + ': ' + item.current.quantity + 'шт.');
                        }
                        break;
                }//end switch
            }
        }
    }

    EmailFunction.prototype.sendToEmail = function(){
        var self = this;
        var params = {
            order: JSON.stringify(self.structure)
        }
        $.ajax({
            url: 'data.php',
            type: 'POST',
            data: { "request": "email_order", "param": JSON.stringify(params) },
            success: function(res){
            },
            error: function(){ 
            }
         });
    }

    function send(order){
        var email = new EmailFunction();
        email.groupingOrderStructure(order);
        email.sendToEmail();
    } 

    function sendPhonenumber(phone, resultCallback) {
        var params = {
            "phone": phone
        }
        ajaxToData("email_phonenumber", params, resultCallback);
    }

    function sendQuestion(name, email, questionBody, token, resultCallback) {
        var params = {
            "name": encodeURIComponent(name),
            "email": email,
            "message": encodeURIComponent(questionBody),
            "token": token
        }
        ajaxToData("email_question", params, resultCallback);
    }

    function ajaxToData(requestName, params, resultCallback) {
        $.ajax({
            type: 'POST',
            url: 'data.php',
            dataType: 'json',
            data: { "request": requestName, "param": JSON.stringify(params) },
                success: function(msg) {
                    if (msg["error"] == undefined) {
                        console.log("Email sent successful!");
                        console.log(JSON.stringify(params));
                        resultCallback(true);
                    } else {
                        console.log("Error on mail send: " + msg["error"] + " ("+ msg["msg"]+ ")");
                        resultCallback(false);
                    }
                },
                error: function( jqXHR, textStatus, errorThrown ) {
                    console.log("Error on mail send (" + textStatus + "): " + errorThrown);
                    resultCallback(false);
                }
        });
    }

    return {
        send: send,
        sendQuestion: sendQuestion,
        sendPhonenumber: sendPhonenumber
    }
    
}])

