angular.module("App")
.service("StatService", ['DeliveryServise', '$timeout', 'EmailService', function (DeliveryServise, $timeout, EmailService) {
    
    var visitSource;
    var localUserId = null;
    var visitType = 1;
    var agentName = null;

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
      
    function uuidv4() {
        var crypto = window.crypto || window.msCrypto;
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    }

    function postVisitToServer(userAction) {
        var params = {
            "clientId": localUserId,
            "sourceId": visitSource == null ? "" : visitSource,
            "userAction": userAction,
            "visitType": visitType
        }

        $.ajax({
            type: 'POST',
            url: 'db.php',
            dataType: 'json',
            data: { "request": "regvisit", "param": JSON.stringify(params) },
                success: function(msg) {
                    if (msg["error"] == undefined) {
                        agentName = msg["msg"];
                        //console.log("Log visit successfull. Agent name: " + msg["msg"]);
                    } else {
                        console.log("Error on visit log: " + msg["error"] + " ("+ msg["msg"]+ ")");
                    }
                },
                error: function( jqXHR, textStatus, errorThrown ) {
                    console.log("Error on visit log (" + textStatus + "): " + errorThrown);
                }
        });
    }

    function registerUserVisit() {
        visitSource = getParameterByName("from");

        var cookies = document.cookie;
        var cookieVarName = "cubyhomeLocalUserId=";

        if (cookies.indexOf(cookieVarName) != -1) {
            var cookiesArr = cookies.split("; ");
            for (var i = 0; i < cookiesArr.length; i++) {
                if (cookiesArr[i].indexOf(cookieVarName) != -1) {
                    localUserId = cookiesArr[i].split("=")[1];
                    break;
                }
            }
        }
        
        if (localUserId == null || localUserId == "") {
            localUserId = uuidv4();
            var expireDate = new Date(new Date().getTime() + 365*24*60*60*1000);
            document.cookie =  cookieVarName + localUserId + "; path=/; expires=" + expireDate.toUTCString();
            visitType = 0;
        }

        if (visitSource != null && visitSource != "" || visitType == 0) {
            postVisitToServer(0);
        }
    }

    function registerUserAction(userAction) {
        postVisitToServer(userAction);
    }

    function getLocalUserId() {
        return localUserId;
    }

    function getAgentName() {
        return agentName;
    }

    return {
        registerUserVisit: registerUserVisit,
        registerUserAction: registerUserAction,
        getLocalUserId: getLocalUserId,
        getAgentName: getAgentName
    }

}]);