var widthWind = $(window).width();
var heightWind = $(window).height();

//----------------------------------------------------
// smooth scroll--------------------------------------------------
$('a[href*="#"]:not([href="#"])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000);
      return false;
    }
  }
});
// ---------------------------------------------------------------------------
//-------slider---------
$('.slick-center').slick({
  fade: true,
  dots: true,
  arrows:false,
  infinite: false,
  speed: 501,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide:0,
  customPaging: function(){
    return `<svg class="dot" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30.09 30.09">
              <title>dot</title>
              <g>
                <g>
                  <circle class="dot-hoop" cx="15.04" cy="15.04" r="13.74" style="fill: none;stroke-miterlimit: 10;stroke-width: 2.60447543653748px;" />
                  <circle class="dot-center" cx="15.04" cy="15.04" r="9.95"/>
                </g>
              </g>
            </svg>`
  }
});

//--------------------------------------------------------------------------
//-----scrollbar---------------------------
$(".big-img-cover").mCustomScrollbar({
    theme:"dark",
    axis:"x"
});
$(".competitors__right").mCustomScrollbar({
    theme:"dark",
    axis:"x"
});
//------------------------------------------------
//-----modal-----------------------------------------------------------
$('.open-modal, .one-priob-img').click(function(e){
    e.preventDefault();
    var id = $(this).attr('id').split("-");
    $('#modal-'+id[1]).modal('show');
});