angular.module("App")

.directive('mapSearch', ["$timeout", "$http", function ($timeout, $http){
  function link($scope, $element, attrs, controllers) { 
    $element.addClass('map-search-widget')
    $scope.showList;
    $scope.filteredCities = [];
    var previousMarker;
    var inputElement = $element.find('.map-search-widget__input');

    // $http({
    //     method: 'GET',
    //     url: '/betar.php'
    // }).success(function (res, status, headers, config) {
    //   console.log(res)
    // }).error(function (data, status, headers, config) {
    //     console.log('can not get cities from betar')
    // });

    // $.ajax({
    //     url: 'betar.php',
    //     type: 'GET',
    //     success: function(res){
    //       console.log(res)
    //     },
    //     error: function(){ 
    //     }
    // });

    $element.find('.map-search-widget__list-body').mCustomScrollbar({
        theme:"dark",
        axis:"y",
        scrollInertia: 0
    });

    $scope.$watch('cities.length', function(newVal, oldVal){
      if(!newVal){
        $element.css({
          'opacity':0,
          'z-index':'-10'
        })
      }
    });

    $scope.$watch('query', handleChangeQuery);

    $scope.goToCity = goToCity;
    $scope.searchButtonHandler = enterHandlerForHint;

    inputElement.on('click', function(){
      $timeout(() => {
        $scope.showList = true;
      })
    })

    $element.on('keydown', keyDownListener);

    $('body').on('click', hideWidget);
    $('body').on('mapMarkerClick', function(e, marker){
      // console.log();
      goToCityByMarker(marker);
    });

    $element.on('click', function(e){
      e.stopPropagation();
    })

    $scope.$on('$destroy', function () {
      $('body').off('click', hideWidget);
      $('body').off('mapMarkerClick');
    });

    function hideWidget(){
      $timeout(function(){
        $scope.showList = false;
      })
    }

    function goToCityByMarker(marker){
      if(!mapGoo || !marker) return;
      if(marker.title){
        $scope.query = marker.title;
      }
      mapGoo.setCenter( new google.maps.LatLng(marker.position.lat(), marker.position.lng()) );
      mapGoo.setZoom(12);
      var city = $scope.cities.find(function(el){
        return !!marker.customId && (marker.customId == el.id);
      })
      if(city === undefined) return;
      if(previousMarker){
        previousMarker.setIcon(imageBlueIcon);
      }
      marker.setIcon(imageWhiteIcon);
      previousMarker = marker;

      showInfoAboutCity(city);
      $scope.notFound = false;
    }

    function goToCity(city){
      if(!mapGoo || !markersGoogleMap) return;
      mapGoo.setCenter( new google.maps.LatLng(city.map[0], city.map[1]) );
      mapGoo.setZoom(12);
      var marker = markersGoogleMap.find(function(el){
        return !!city.id && (el.customId == city.id);
      })
      if(marker === undefined) return;
      if(previousMarker){
        previousMarker.setIcon(imageBlueIcon);
      }
      marker.setIcon(imageWhiteIcon);
      previousMarker = marker;

      showInfoAboutCity(city);

      closeList();
      $scope.notFound = false;
    }

    function showInfoAboutCity(city){
      $scope.$evalAsync( () => {
        $scope.showInfoCity.selectedCity = city;
        $scope.showInfoCity.show = true;
      })
    }

    function handleChangeQuery(newVal, oldVal){
      if(newVal === oldVal) return;
      if( $scope.disableFilter ){
        $scope.disableFilter = false;
      }else{
        $scope.queryFiltered = newVal;
      } 
    }

    function keyDownListener(event){
      if(!$scope.filteredCities.length) return;
      switch(event.which){
        case 38: //up
          event.preventDefault();
          goUp();
          $scope.$apply();
          break;
        case 40: //down
          event.preventDefault();
          goDown();
          $scope.$apply();
          break;
        case 13: //enter
          enterHandlerForHint();
          $scope.$apply();
          break;
        case 27: //Esc
          closeList();
          break;
        default:
          defaultKeyDownHandler();
      }
    };


    function defaultKeyDownHandler(){
      $scope.notFound = false;
      $scope.cities.forEach( (el) => {
        el.focused = false;
      })
    }

    function closeList(){
      $scope.$evalAsync(function(){ 
        resetSelectCity();
        $scope.showList = false 
        inputElement.trigger('blur');
      });
    }

    function enterHandlerForHint(){
      if( !$scope.query ){
        return;
      }
      $scope.notFound = false;
      var city = $scope.filteredCities.find( (el) => {
        if(el.city_name){
          return $scope.query.toLowerCase() === el.city_name.toLowerCase();
        }else{
          return false;
        }
      })
      if(city){
        goToCity(city);
        closeList();
        return;
      } else {
        $scope.notFound = true;
      }
    }

    function resetSelectCity(){
      $scope.cities.forEach( (el) => {
        el.focused = false;
      })
    }

    function goDown(){
      if( !checkInitialConditions() ) return;
      $scope.disableFilter = true;
      $scope.notFound = false;
      var focusedIndex = $scope.filteredCities.findIndex( (el) => {
        return el.focused === true;
      });
      if(focusedIndex !== -1){
        $scope.filteredCities[focusedIndex].focused = false;
        if( $scope.filteredCities[focusedIndex+1] ){
          $scope.filteredCities[focusedIndex+1].focused = true;
          focusedIndex = focusedIndex + 1;
        } else {
          $scope.filteredCities[0].focused = true;
          focusedIndex = 0;
        }
      } else {
        $scope.filteredCities[0].focused = true;
        focusedIndex = 0;
      }
      scrollForArrowsNavig('goDown', focusedIndex);
      $scope.query = $scope.filteredCities[focusedIndex].city_name;
    }
    function goUp(){
      if( !checkInitialConditions() ) return;
      $scope.disableFilter = true;
      $scope.notFound = false;
      var focusedIndex = $scope.filteredCities.findIndex( (el) => {
        return el.focused === true;
      });
      if(focusedIndex !== -1){
        $scope.filteredCities[focusedIndex].focused = false;
        if( $scope.filteredCities[focusedIndex-1] ){
          $scope.filteredCities[focusedIndex-1].focused = true;
          focusedIndex = focusedIndex - 1;
        } else {
          $scope.filteredCities[$scope.filteredCities.length-1].focused = true;
          focusedIndex = $scope.filteredCities.length-1;
        }
      } else {
        $scope.filteredCities[$scope.filteredCities.length-1].focused = true;
        focusedIndex = $scope.filteredCities.length-1;
      }
      scrollForArrowsNavig('goUp', focusedIndex);
      $scope.query = $scope.filteredCities[focusedIndex].city_name;
    }

    function checkInitialConditions(){
      if( !Array.isArray($scope.filteredCities) ) return false;
      return true;
    }

    function scrollForArrowsNavig(direction, index) {
        var ul = $element.find('.mCSB_container');
        if( !ul.length ) return;
        var scroll = Math.abs(parseFloat(ul.css('top')));
        var listInView = $element.find('.map-search-widget__list-body'); 
        var lis = $element.find('.map-search-widget__list-item');
        var viewHeight = parseFloat(listInView.css('height'));
        
        
        if (direction == 'goDown') {
          scrollDown(lis, index, scroll, viewHeight, listInView)
        } else {
          scrollUp(lis, index, scroll, viewHeight, listInView)
        }

    }

    function scrollUp(lis, index, scroll, viewHeight, listInView){
      var lisHeight = 0;
      lis.each(function (ind) {
          if (ind <= index - 1) lisHeight += parseFloat($(this).css('height'));
      });
      if (lisHeight < scroll){
        listInView.mCustomScrollbar("scrollTo", lisHeight);
        return;
      } 
      if (lisHeight > scroll + viewHeight){
        scrollDown(lis, index, scroll, viewHeight, listInView);
        return;
      } 
    }
    function scrollDown(lis, index, scroll, viewHeight, listInView){
      var lisHeight = 0;
      lis.each(function (ind) {
          if (ind <= index) lisHeight += parseFloat($(this).css('height'));
      });
      if (lisHeight > scroll + viewHeight){
        listInView.mCustomScrollbar("scrollTo", lisHeight - viewHeight);
        return;
      } 
      if (lisHeight < scroll){
        scrollUp(lis, index, scroll, viewHeight, listInView);
        return;
      }
    }

    

    



  }
  
  return {
    scope:{ 
      cities:"=",
      showInfoCity:"="
    },
    link: link,
    template:`
    <div>
      <div class="map-search-widget__input-block">
        <div ng-show="notFound" class="map-search-widget__not-found">
          Не найдено
        </div>
        <input class="map-search-widget__input"
              ng-class="{'map-search-widget__input_error':notFound}" 
              type="text"
              placeholder="Найти город"
              ng-model="query" />
        <button class="map-search-widget__search-button"
                ng-click="searchButtonHandler()" >
          <magnifier-svg></magnifier-svg>
        </button>
      </div>
      <div ng-show="showList && filteredCities.length" class="map-search-widget__list">
        <div class="map-search-widget__list-body">
          <div class="map-search-widget__list-item"
                ng-repeat="city in filteredCities = (cities | filter:queryFiltered)"
                ng-class="{'map-search-widget__list-item_selected':city.focused}"
                ng-click="goToCity(city)">
                {{city.city_name}}
          </div>
        </div>
      </div>
    </div>`
  }   
}])




.directive('infoCity', ["$timeout", function ($timeout){
  function link($scope, $element, attrs, controllers) { 
    $element.addClass('info-city-widget');

    $element.find('.info-city-widget__body').mCustomScrollbar({
        theme:"dark",
        axis:"y"
    });

    $element.on('click', function(e){
      e.stopPropagation();
    });

    $scope.$watch('info.selectedCity.address', function(newVal, oldVal){
      $scope.address = newVal;
      if(newVal){
        $scope.address = newVal
                          .replace(/&quot;/g, '\"')
                          .split('\n');
      }
    })

    $scope.closeView = function(){
      markersGoogleMap.forEach(function(marker){
        if(marker.title === "Уфа"){
          marker.setIcon(imageUfa);
        }else{
          marker.setIcon(imageBlueIcon);
        }
      })

      $scope.info.show=false;
      mapGoo.setZoom(4);
      mapGoo.setCenter( new google.maps.LatLng(57.8656, 69.3646) );

    }


  }

  return {
    scope:{
      info:"="
    },
    link: link,
    template:`
    <div ng-show="info.show" class="content">
      <div class="info-city-widget__header">
        <div class="info-city-widget__close"
            ng-click="closeView()">
            <close-svg></close-svg>
        </div>
        <div class="info-city-widget__title">
          {{info.selectedCity.city_name}}
        </div>
      </div>
      <div class="info-city-widget__body">
        <div class="info-city-widget__body-group">
          <div class="info-city-widget__company">
            {{info.selectedCity.name}}
          </div>
          <div class="info-city-widget__address">
            <div ng-repeat="ad in address">
              {{ad}}
            </div>
          </div>
        </div>
        <div class="info-city-widget__contacts info-city-widget__body-group">
          <div ng-show="info.selectedCity.phones && info.selectedCity.phones.length > 0" class="info-city-widget__contacts-item">
            <span>Телефон:</span> <span>{{info.selectedCity.phones.join(", ")}}</span>
          </div>
          <div ng-show="info.selectedCity.email" class="info-city-widget__contacts-item">
            <span>Email:</span> 
              <span>
                <a href="mailto:{{info.selectedCity.email}}">
                  {{info.selectedCity.email}}
                <a>
              </span>
          </div>
          <div ng-show="info.selectedCity.site" class="info-city-widget__contacts-item">
            <span>Сайт:</span> 
            <span>
              <a href="http://{{info.selectedCity.site}}" target="_blank">
                {{info.selectedCity.name || info.selectedCity.site}}
              <a>
            </span>
          </div>
        </div>
      </div>
    </div>`
  }   
}])