angular.module("App")

.directive('myTooltip', ["$compile", "$timeout", function ($compile, $timeout){
  function link($scope, $element, attrs, controllers) { 
    var str = $scope.notHover ? "title" : "visible && title";
    var template = angular.element('<span ng-if="title" ng-show="'+str+'" my-cloak class="my-tooltip-class {{direction}} {{color}}">{{title}}</span>');
    $element.append(template);
    $compile(template)($scope);

    if($scope.notHover){
      var input = $element.find("input");
      input.on('blur', function(){
        $scope.title = "";
      });
      $scope.$on('$destroy', function () {
        input.off('blur');
      });
    }

    if($scope.notHover) return;
    $element.hover( 
    function() {
      $scope.visible = true; 
      $scope.$apply();
    }, function() {
      $scope.visible = false;
      $scope.$apply();
    });
    $timeout(function(){
      $scope.visible = false;
    });
  }

  return {
    scope:{ 
      title: "=titleTooltip",
      direction: "@tooltipDirection",
      color: "@tooltipColor",
      notHover: "@"
    },
    link: link
  }   
}])

.directive('myTooltipHtml', ["$timeout", function ($timeout){
  function link($scope, $element, attrs, controllers) { 
    $element.parent().hover( 
      function() {
        $scope.visible = true; 
        $scope.$apply();
      }, function() {
        $scope.visible = false;
        $scope.$apply();
      });
      $timeout(function(){
        $scope.visible = false;
      });

    
  }

  return {
    scope:{},
    transclude: true,
    link: link,
    template: '<div ng-transclude ng-show="visible" class="my-tooltip-class"></div>'
  }   
}])

.directive('myCloak', ["$timeout", function($timeout) {
  return {
    restrict: 'A',
    scope:{},
    link: function ($scope, element, attrs, controllers) {
      $timeout(function () {
        element.removeAttr("my-cloak");
        $scope.$destroy();
      }, 1000);
    }
  };
}])

.directive("radioDirective", [function() {
    return {
        link: function($scope, $element){
          
          $scope.device.items.forEach( (item, index) => {
            if(item === $scope.value) $scope.currentIndex = index;
          })

          $scope.checkHandler = function(){
            if( $scope.ngDisabled ) return;
            $scope.device.current = $scope.currentIndex;
          };

        },
        scope:{
            device:"=",
            value:"=",
            name:"@",
            ngDisabled:"=?"
        },
        template:`<div ng-class="{disabledRadiobutton:ngDisabled}" class="radio-directive-class" ng-click="checkHandler()">
                    <div class="radio-directive-class__cell">
                      <radio-svg state="device.current === currentIndex"></radio-svg>
                    </div>
                    <div class="radio-directive-class__cell">
                      {{ value }}
                    </div> 
                </div>`
    }
}])

.directive("colorDirective", [function() {
    return {
        link: function($scope, $element){

          if($scope.value){
            $scope.device.items.forEach( (item, index) => {
              if(item === $scope.value) $scope.currentIndex = index;
            })
          }

          $scope.checkHandler = function(){
            if( !$scope.value ) return;
            $scope.device.current = $scope.currentIndex;
          };

        },
        scope:{
            device:"=",
            name:"@",
            value:"=?"
        },
        template:`<div class="radio-directive-class" ng-click="checkHandler()">
                    <div class="radio-directive-class__cell">
                      <color-radio-svg ng-show="value" color="{{value === 'Белый' ? '#fff' : '#000'}}" state="device.current === currentIndex"></color-radio-svg>

                      <color-radio-svg ng-show="!value" color="{{device.items[device.current] === 'Белый' ? '#fff' : '#000'}}" state="true"></color-radio-svg>
                    </div>
                    <div class="radio-directive-class__cell">
                      {{value}}
                    </div> 
                </div>`
    }
}])


.directive('loadingDirective', ['$timeout', function($timeout) {
  return {
    replace: true,
    scope:{
      stage: '=',
      classDop: '@?'
    },
    template: `<span ng-class="[classDop, {active:stage.active, success:stage.success}]" class="uil-rolling-css">
      <svg ng-show="stage.success" class="svg-success-icon-load" xmlns="http://www.w3.org/2000/svg" viewBox="-263.5 236.5 26 26">
          <g class="svg-success">
          <path d="M-256.46 249.65l3.9 3.74 8.02-7.8"/>
          </g>
      </svg>
    </span>`,
    link: function($scope, $element, attrs, modelCtrl) {
      var cancel;

      $scope.$watch('stage.active', function(newVal, oldVal) {
        if(newVal === true) {
          cancel = $timeout(function(){
            $scope.stage.active = false;
            // dialogsService.okDialog("Ошибка!", 'Проблемы соединения интернета', "Ok", "alert"); 
          }, 8000);
        } else {
          $timeout.cancel(cancel);
        }     
      });
      
      $scope.$watch('stage.success', function(newVal, oldVal){
        if(!newVal) return;
        $timeout.cancel(cancel);
        $scope.stage.active = false;
        $timeout(function(){ $scope.stage.success = false }, 1000);
      });

      $scope.$on('$destroy', function () {
        $timeout.cancel(cancel);
      });

    }
  };
}])
