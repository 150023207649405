angular.module("App")

.directive('navigOrder', [function(){
  return {
    replace: true,
    transclude: true,
    scope: {
        index: "@",
        go:"=",
        pages:"="
    },
    template: `<div ng-click="go($event, index*1)" ng-class="{active:pages[index].active, completed:pages[index].completed}" class="one-nav">
                <div class="circle-nav">
                    <span ng-show="!pages[index].completed">{{(index*1+1)}}</span>
                    <svg ng-show="pages[index].completed" class="svg-success-icon" xmlns="http://www.w3.org/2000/svg" viewBox="-263.5 236.5 26 26">
                        <g class="svg-success">
                        <path d="M-256.46 249.65l3.9 3.74 8.02-7.8"/>
                        </g>
                    </svg>
                </div>
                <div class="text" ng-transclude></div>
            </div>`
  };
}])


.directive('counterOrder', [function(){
  function link($scope, $element){
    $scope.increase = function(){
        if( $scope.max === '' || $scope.max === undefined){
            $scope.ngModel++;
            return;
        }
        if(($scope.ngModel + 1) <= $scope.max) $scope.ngModel++;
    }
    $scope.decrease = function(){
        if(($scope.ngModel - 1) >= 0) $scope.ngModel--;
    }
    $scope.$watch('ngModel', function(newVal, oldVal){
        if( !$scope.max ) return;
        var val = parseInt(newVal, 10);
        if(val > $scope.max){
            $scope.ngModel = $scope.max;
            return;
        }
        if(val < 0){
            $scope.ngModel = 0;
            return;
        }
        $scope.ngModel = val;
    });
  }
  return {
    link: link,
    replace: true,
    scope: {
        ngModel: "=",
        max:"@?"
    },
    template: `<div class="counter-class">
                    <div ng-click="decrease()">
                        <span ng-class="{disab: ngModel===0}" class="decrease span-bt"></span>
                    </div>
                    <input ng-model="ngModel" type="text">
                    <div ng-click="increase()">
                        <span ng-class="{disab: max && (ngModel == max)}" class="increase span-bt"></span>
                    </div>
                </div>`
  };
}])

.directive("emailValidate", [ function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
          ctrl.$validators.emailValidate = function(modelValue, viewValue) {
            if (ctrl.$isEmpty(modelValue)) {
              // consider empty models to be valid
              return false;
            }
            if (myLib.emailValidate(viewValue)) {
              return true;
            }

            // it is invalid
            return false;
          };
        }
    }
}])

.directive("inputEmptyValidate", [ function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
          ctrl.$validators.inputEmptyValidate = function(modelValue, viewValue) {
            if (ctrl.$isEmpty(modelValue)) {
              // consider empty models to be valid
              return false;
            }
        
            return true;
          };
        }
    }
}])

.directive('kladr', ['$timeout', function($timeout){
  function link($scope, $element){

    $scope.blurHandler = function($event){
        $scope.forYandex();  
        $timeout(function(){
            if($scope.ngModel !== $event.target.value){
                $scope.ngModel = $event.target.value;
            }
        }, 50);
    }

    $scope.doBlur = function($event){
        if( $event.keyCode !== 13) return;
        $event.target.blur();
    } 

  };

  return {
    link: link,
    restrict: 'E',
    scope: {
        ngModel: "=",
        forYandex: "&forYandex"
    },
    template:`<div class="kladr-class">
                <input input-empty-validate 
                        name="{{name}}"
                        id="autocompleteAddress"
                        ng-blur="blurHandler($event)"
                        ng-keydown="doBlur($event)"
                        ng-model="ngModel" 
                        type="text" 
                        autocomplete="off">
            </div>`
    
  };
}])
// order.Connect.label
.directive('labelStyle', [function(){
  function link($scope, $element){
    $scope.$watch('text', function(newVal, prevValue){
        if(newVal){
            var arr = newVal.split(' ');          
            $scope.first = arr.slice(0, arr.length-1).join(' ');
            $scope.last = arr[arr.length-1];            
        }else{
            $scope.first = '';
            $scope.last = '';
        }
    })
  }

  return {
    link: link,
    restrict: 'E',
    scope: {
        text: "@"
    },
    template:
    `<span class="styled">{{first}}</span>
    <strong>{{last}}</strong>`
  };

}])

.directive("animHighlight", ["$animate", "$timeout", function ($animate, $timeout) {
    function link(scope, element, attrs) {

        scope.internalControl = scope.shakingInput;
        // console.log(scope.control);

        scope.internalControl[scope.control] = function () {
            $animate.addClass(element, scope.highlightWithClass)
            .then(function () {
                var animationDuration = parseInt($(element).css("animation-duration")) * 1000;
                $timeout(function () {
                    $animate.removeClass(element, scope.highlightWithClass);
                }, animationDuration);
            });
        };
    };

    return {
        link: link,
        scope: {
            highlightWithClass: "@",
            control: "@",
            shakingInput: "=",
        }
    };
}])

.directive("selectOptions", ["$timeout", function($timeout) {
    return {
        scope: { optionsselect: '=',
                  selectOptionsClass: "@?",
                  pluginUpdate: '=?'
        },
        link: function ($scope, $element, $attrs, $ngModelCtrl) {
            var inited = false;

            function initDropdown() {
                inited = true;
                $element.easyDropDown({
                    cutOff: 4,
                    wrapperClass: 'dropdownPlugin' + ' ' + $scope.selectOptionsClass,
                    onChange: function (selected) {}
                });
            };

            $scope.$watch("optionsselect", function(newValue, oldValue) {
                if(!newValue) return;
                if(inited === true){
                    $element.easyDropDown('destroy');
                }
                initDropdown();
            }, true);     

            // $scope.$watch("pluginUpdate", function(newValue, oldValue) {
            //     if(newValue === oldValue) return;
            //     if($element.easyDropDown){
            //       $element.easyDropDown('destroy');
            //       initDropdown();
            //     }
                
            // });      
            

        }
    }
}])