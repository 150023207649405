var addressMap = 
[
    
    {
      "id": "145",
      "name": "Бетар-Владимир",
      "image": "",
      "city": "71",
      "city_name": "Владимир",
      "address": "600035, г.Владимир, ул.Куйбышева, д.26к, РЦ &quot;Самохвал&quot;",
      "email": "betar-vl@rambler.ru",
      "site": "",
      "map": [
        "56.169222353747",
        "40.395624779762"
      ],
      "phones": [
        "(4922) 47-12-11",
        "   8-9066-11-68-86"
      ],
      "contacts": null
    },
    {
      "id": "154",
      "name": "БЕТАР-Калининград",
      "image": "",
      "city": "80",
      "city_name": "Калининград",
      "address": "236006, г.Калининград, ул.Октябрьская, д.29 А, офис № 11",
      "email": null,
      "site": "",
      "map": [
        "54.702481063172",
        "20.517006737434"
      ],
      "phones": [
        "(4012) 361-341",
        " 361-342",
        " 361-323"
      ],
      "contacts": null
    },
    {
      "id": "156",
      "name": "Бетар-Кострома",
      "image": "",
      "city": "82",
      "city_name": "Кострома",
      "address": "156014, г.Кострома,  6-й Давыдовский проезд, д.16",
      "email": "betar-kstrm@rambler.ru",
      "site": "",
      "map": [
        "57.741815102016",
        "41.001515804893"
      ],
      "phones": [
        "(4942) 30-11-85"
      ],
      "contacts": null
    },
    {
      "id": "172",
      "name": "БЕТАР-Пенза",
      "image": "",
      "city": "97",
      "city_name": "Пенза",
      "address": "440067, г.Пенза, ул. Горная, д.3а, офис 16",
      "email": "fluram@yandex.ru",
      "site": "",
      "map": [
        "53.193315222004",
        "44.98835696627"
      ],
      "phones": [
        "(8412) 45-04-63",
        " 8-9050-21-33-39"
      ],
      "contacts": null
    },
    {
      "id": "140",
      "name": "БЕТАР-Ресурс",
      "image": "",
      "city": "66",
      "city_name": "Балаково",
      "address": "413800, Саратовская область, г. Балаково, ул.Ленина, д.116",
      "email": "bal.magazin@mail.ru",
      "site": "",
      "map": [
        "52.023134572062",
        "47.782452504299"
      ],
      "phones": [
        "(8453) 44-91-60",
        " 46-16-40"
      ],
      "contacts": null
    },
    {
      "id": "178",
      "name": "Бетар-Рязань",
      "image": "",
      "city": "103",
      "city_name": "Рязань",
      "address": "390023, г.Рязань, проезд Яблочкова, д.8Г",
      "email": "betar-rzn@rambler.ru",
      "site": "",
      "map": [
        "54.609922070063",
        "39.775878686508"
      ],
      "phones": [
        "(4912) 99-11-75"
      ],
      "contacts": null
    },
    {
      "id": "192",
      "name": "Бетар-Ярославль",
      "image": "",
      "city": "118",
      "city_name": "Ярославль",
      "address": "150049, г.Ярославль, ул.Вспольинское Поле, д.14",
      "email": "yar@avangard-santehnika.ru ",
      "site": "бетар-ярославль.рф/",
      "map": [
        "57.619236053274",
        "39.840313877316"
      ],
      "phones": [
        "8-4852-33-94-35"
      ],
      "contacts": null
    },
    {
      "id": "38",
      "name": "ЗАО \"МЕТТЭМ-БЕТАР\"",
      "image": "",
      "city": "39",
      "city_name": "Москва",
      "address": "140090, Московская обл., г.Дзержинский, ул.Энергетиков, д.4",
      "email": "betar_moscow@bk.ru",
      "site": "www.betar-moskva.ru/",
      "map": [
        "55.641050341483",
        "37.83407532209"
      ],
      "phones": [
        "(985) 764-98-34",
        " (495) 550-20-44"
      ],
      "contacts": null
    },
    {
      "id": "162",
      "name": "ИП Молчанов С.В.",
      "image": "",
      "city": "87",
      "city_name": "Курск",
      "address": "305004, г.Курск, ул.Литовская, д.95а/3, офис 215",
      "email": "fluram@yandex.ru",
      "site": "",
      "map": [
        "51.705545399533",
        "36.171498949074"
      ],
      "phones": [
        "(4712) 39-34-54"
      ],
      "contacts": null
    },
    {
      "id": "180",
      "name": "ИП Молчанов С.В.",
      "image": "",
      "city": "105",
      "city_name": "Саранск",
      "address": "430003, Республика Мордовия, г.Саранск, пр-кт Ленина, д.91, оф.3",
      "email": null,
      "site": "fluram@yandex.ru",
      "map": [
        "54.207783727205",
        "45.192010364418"
      ],
      "phones": [
        "8-905-021-33-39",
        " (84342) 4-48-49"
      ],
      "contacts": null
    },
    {
      "id": "194",
      "name": "ИП Молчанов С.В.",
      "image": "",
      "city": "120",
      "city_name": "Тула",
      "address": "300045, г.Тула, ул.Рогожинская, д.18",
      "email": "fluram@yandex.ru ",
      "site": "",
      "map": [
        "54.173576870291",
        "37.626150254631"
      ],
      "phones": [
        "8-905-021-33-39",
        " 8-905-021-33-40"
      ],
      "contacts": null
    },
    {
      "id": "199",
      "name": "ИП Молчанов С.В.",
      "image": "",
      "city": "124",
      "city_name": "Брянск",
      "address": "241050, г.Брянск, ул.Советская д.92 Б, оф.№5",
      "email": "fluram@yandex.ru",
      "site": "",
      "map": [
        "53.259668704464",
        "34.343538050926"
      ],
      "phones": [
        "8-9050-21-33-40"
      ],
      "contacts": null
    },
    {
      "id": "202",
      "name": "ИП Молчанов С.В.",
      "image": "",
      "city": "126",
      "city_name": "Белгород",
      "address": "308006, г.Белгород, ул.Корочанская, д.132 А, офис № 13",
      "email": "fluram@yandex.ru",
      "site": "",
      "map": [
        "50.590433721981",
        "36.62641632209"
      ],
      "phones": [
        "8-9050-22-69-33"
      ],
      "contacts": null
    },
    {
      "id": "206",
      "name": "ИП Молчанов С.В.",
      "image": "",
      "city": "130",
      "city_name": "Орел",
      "address": "302008, г.Орел, ул.Машиностроительная, д.6, пом.14, оф.33 б",
      "email": "kit-serv@mail.ru",
      "site": "",
      "map": [
        "52.919017235705",
        "35.995355847885"
      ],
      "phones": [
        "8-9050-20-99-65"
      ],
      "contacts": null
    },
    {
      "id": "137",
      "name": "Компания \"ЖИГС-Ундарга\"",
      "image": "",
      "city": "61",
      "city_name": "Улан-Батор",
      "address": "Монголия, г.Улан-Батор СБД 10 хороо\r\nЦагдаагийн гудамж 6",
      "email": "irbuyan2013@yandex.com",
      "site": "",
      "map": [
        "47.91594534814",
        "106.89437055029"
      ],
      "phones": [
        "+976-11-35-08-53",
        " +976-99-08-54-09"
      ],
      "contacts": null
    },
    {
      "id": "190",
      "name": "Магазин \"БЕТАР\" в г.Чистополь",
      "image": "",
      "city": "116",
      "city_name": "Чистополь",
      "address": "422980, г. Чистополь, ул. К.Маркса, д.121 Н1",
      "email": "betar2007@mail.ru",
      "site": "www.shopbetar.ru/",
      "map": [
        "55.355425969053",
        "50.658440237434"
      ],
      "phones": [
        "(84342) 5-14-42",
        " факс 5-34-74"
      ],
      "contacts": null
    },
    {
      "id": "182",
      "name": "Магазин \"Тёплый Дом\"",
      "image": "",
      "city": "107",
      "city_name": "Тамбов",
      "address": "г.Тамбов, бульвар Энтузиастов, д.2К\r\nр-н &quot;Завод Аппарат&quot;\r\nМагазин &quot;Тёплый Дом&quot;\r\nграфик работы: с ПН-Пт с 9-19 ч.\r\n                            Сб-Вс с 9-17 ч.",
      "email": null,
      "site": "",
      "map": [
        "52.757979589426",
        "41.437821550926"
      ],
      "phones": [
        "(4752) 79-35-35",
        " 8-910-350-33-64"
      ],
      "contacts": null
    },
    {
      "id": "135",
      "name": "НП ООО \"Эрготех\"",
      "image": "",
      "city": "59",
      "city_name": "Минск",
      "address": "220004, Республика Беларусь, г.Минск, Амураторская, 4",
      "email": "y7537645@rambler.ru",
      "site": "",
      "map": [
        "53.910584081817",
        "27.529717275463"
      ],
      "phones": [
        "(10-375-17) 207-08-38",
        " 224-21-62",
        " 224-10-11"
      ],
      "contacts": null
    },
    {
      "id": "146",
      "name": "ООО \"Ариком\"",
      "image": "",
      "city": "72",
      "city_name": "Волгоград",
      "address": "400066, г.Волгоград, ул.Циолковского, д.14",
      "email": null,
      "site": "",
      "map": [
        "48.695954920176",
        "44.502119504299"
      ],
      "phones": [
        "(8442) 97-21-28",
        " 93-60-60"
      ],
      "contacts": null
    },
    {
      "id": "168",
      "name": "ООО \"БЕТАР НН\"",
      "image": "",
      "city": "93",
      "city_name": "Нижний Новгород",
      "address": "603152, г.Нижний Новгород, ул.Кащенко, д.6Г, оф.31",
      "email": "betar-nn@mail.ru",
      "site": "",
      "map": [
        "56.246053489403",
        "43.980766686508"
      ],
      "phones": [
        "8-930-283-07-50"
      ],
      "contacts": null
    },
    {
      "id": "138",
      "name": "ООО \"Бетар Север\"",
      "image": "",
      "city": "64",
      "city_name": "Архангельск",
      "address": "164521, Архангельская обл., г.Северодвинск, ул.Железнодорожная, д.34",
      "email": "betarnord@yandex.ru",
      "site": "",
      "map": [
        "64.561253414766",
        "39.856531059524"
      ],
      "phones": [
        " 8-911-577-28-87",
        "  8-900-918-57-57"
      ],
      "contacts": null
    },
    {
      "id": "148",
      "name": "ООО \"БЕТАР-АЛЬЯНС\"",
      "image": "",
      "city": "74",
      "city_name": "Воронеж",
      "address": "г.Воронеж, ул.Туполева, д.5В",
      "email": "betar.tlt@yandex.ru",
      "site": "",
      "map": [
        "51.642920824071",
        "39.277127868717"
      ],
      "phones": [
        "(4732) 61-74-61",
        " 61-74-60"
      ],
      "contacts": null
    },
    {
      "id": "183",
      "name": "ООО \"БЕТАР-АЛЬЯНС\"",
      "image": "",
      "city": "109",
      "city_name": "Тольятти",
      "address": "445044, Самарская обл., г.Тольятти, ул. Дзержинского 38",
      "email": "betar.tlt@yandex.ru",
      "site": "",
      "map": [
        "53.534570137883",
        "49.32461467791"
      ],
      "phones": [
        "(8482) 76-21-74"
      ],
      "contacts": null
    },
    {
      "id": "186",
      "name": "ООО \"БЕТАР-АЛЬЯНС\"",
      "image": "",
      "city": "112",
      "city_name": "Ульяновск",
      "address": "432073, г.Ульяновск, ул.Жигулевская, д.17 (м-н &quot;Сантехника&quot;)",
      "email": "betar.tlt@yandex.ru",
      "site": "",
      "map": [
        "54.27494226475",
        "48.30244846627"
      ],
      "phones": [
        "8-903-337-73-81"
      ],
      "contacts": null
    },
    {
      "id": "152",
      "name": "ООО \"Бетар-Вятка\"",
      "image": "",
      "city": "78",
      "city_name": "Йошкар-Ола",
      "address": null,
      "email": "andrey-maloletkov@mail.ru",
      "site": "",
      "map": [
        ""
      ],
      "phones": [
        "8-9372-82-60-68",
        "  8-9179-15-00-80 8-9372-82-60-78"
      ],
      "contacts": null
    },
    {
      "id": "157",
      "name": "ООО \"БЕТАР-Вятка\"",
      "image": "",
      "city": "83",
      "city_name": "Киров",
      "address": "610037, г.Киров, Солнечный проезд, д.1А, кабинет 101",
      "email": "andrey-maloletkov@mail.ru",
      "site": "",
      "map": [
        "58.58053",
        "49.595349"
      ],
      "phones": [
        "8-9372-82-60-68",
        " 8-9372-82-60-78",
        " 8-9179-15-00-80"
      ],
      "contacts": [
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        }
      ]
    },
    {
      "id": "188",
      "name": "ООО \"Бетар-Вятка\"",
      "image": "",
      "city": "114",
      "city_name": "Чебоксары",
      "address": "Республика Чувашия, г.Чебоксары, ул. Гладкова, 10\r\nТорговый комплекс &quot;Ярмарка&quot; ряд.6, место 22",
      "email": "andrey-maloletkov@mail.ru",
      "site": "",
      "map": [
        "56.11429778723",
        "47.248336297623"
      ],
      "phones": [
        "8-9372-82-60-68",
        "    8-9179-15-00-80",
        " 8-9372-82-60-78",
        "    8-9033-225-255"
      ],
      "contacts": null
    },
    {
      "id": "195",
      "name": "ООО \"БЕТАР-Вятка\"",
      "image": "",
      "city": "121",
      "city_name": "Сыктывкар",
      "address": null,
      "email": "andrey-maloletkov@mail.ru",
      "site": "",
      "map": [
        "61.681719141593",
        "50.804107989258"
      ],
      "phones": [
        " 8-9372-82-60-68",
        " 8-9179-15-00-80",
        " 8-9372-82-60-78"
      ],
      "contacts": null
    },
    {
      "id": "139",
      "name": "ООО \"БЕТАР-Дон\"",
      "image": "",
      "city": "65",
      "city_name": "Астрахань",
      "address": "414041, г.Астрахань, ул.Ереванская, д.3",
      "email": null,
      "site": "",
      "map": [
        "46.371517625557",
        "48.087134182209"
      ],
      "phones": [
        "8(927)662-05-13"
      ],
      "contacts": null
    },
    {
      "id": "177",
      "name": "ООО \"БЕТАР-Дон\"",
      "image": "",
      "city": "102",
      "city_name": "Ростов-на-Дону",
      "address": "344029, г.Ростов-на-Дону, ул.Плужная, д.12",
      "email": null,
      "site": "",
      "map": [
        "47.255790206148",
        "39.768038237434"
      ],
      "phones": [
        "(863) 254-60-54"
      ],
      "contacts": null
    },
    {
      "id": "176",
      "name": "ООО \"БЕТАР-Кубань\"",
      "image": "",
      "city": "101",
      "city_name": "Пятигорск",
      "address": "357502, г.Пятигорск,ул.Московская, д.2, м-н &quot;Часы, счетчики&quot;\r\nг.Пятигорск, Бештаугорское шоссе, м-н &quot;Счетчики",
      "email": "idem.55@mail.ru",
      "site": "",
      "map": [
        "44.045795420993",
        "43.063266368717"
      ],
      "phones": [
        "(8793) 33-32-76",
        " (8793) 40-56-24"
      ],
      "contacts": null
    },
    {
      "id": "171",
      "name": "ООО \"БЕТАР-Оренбург\"",
      "image": "",
      "city": "96",
      "city_name": "Оренбург",
      "address": "460000, г.Оренбург, ул.Монтажников, д.23 (2-х этажное здание)",
      "email": "betar-orenburg@mail.ru",
      "site": "",
      "map": [
        "51.802172178562",
        "55.155109673611"
      ],
      "phones": [
        "(3532) 75-51-65",
        " 25-25-47"
      ],
      "contacts": null
    },
    {
      "id": "149",
      "name": "ООО \"БЕТАР-Урал\"",
      "image": "",
      "city": "75",
      "city_name": "Екатеринбург",
      "address": "620034, г.Екатеринбург, ул.Колмогорова, 3, офис 200",
      "email": "info@betar-ural.ru",
      "site": "www.betar-ural.ru/",
      "map": [
        "56.847163567861",
        "60.55679003373"
      ],
      "phones": [
        "(343) 245-19-47",
        " 245-16-90"
      ],
      "contacts": null
    },
    {
      "id": "187",
      "name": "ООО \"БетарУфа\"",
      "image": "",
      "city": "113",
      "city_name": "Уфа",
      "address": "450078, Республика Башкортостан, г.Уфа, Кирова, 103\r\n450511, Республика Башкортостан, г.Уфа, ул.Придорожная, д.2\r\n450049, г.Уфа, ул.Новоженова, д.90/1",
      "email": "sale@betarufa.ru",
      "site": "betarufa.ru/",
      "map": [
        "54.72627306995",
        "55.982436601852"
      ],
      "phones": [
        "(347) 228-48-53",
        " 228-05-10",
        " 274-49-20",
        " 274-47-16",
        " 292-37-72",
        " 292-42-87"
      ],
      "contacts": null
    },
    {
      "id": "141",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "67",
      "city_name": "Барнаул",
      "address": "656065, г.Барнаул, ул.Энтузиастов, д.52",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "53.357709326684",
        "83.65484502943"
      ],
      "phones": [
        "(3852) 60-65-20"
      ],
      "contacts": null
    },
    {
      "id": "155",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "80",
      "city_name": "Кемерово",
      "address": "г.Кемерово, ул.Володарского, д.18, оф.107",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "55.344835911679",
        "86.064809593254"
      ],
      "phones": [
        "(3842) 63-00-88"
      ],
      "contacts": null
    },
    {
      "id": "160",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "85",
      "city_name": "Красноярск",
      "address": "г.Красноярск, ул.Пограничников, д.105а",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "56.074992067029",
        "92.998715576057"
      ],
      "phones": [
        ""
      ],
      "contacts": null
    },
    {
      "id": "161",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "86",
      "city_name": "Курган",
      "address": "г.Курган, 3 микрорайон, д.1 А",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "55.460249857843",
        "65.257129135582"
      ],
      "phones": [
        "(3522) 55-28-88"
      ],
      "contacts": null
    },
    {
      "id": "164",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "89",
      "city_name": "Магнитогорск",
      "address": "г.Магнитогорск, ул.50 лет Магнитки, д.33а, оф.201",
      "email": "340355@mail.ru",
      "site": "vodomer74.ru/",
      "map": [
        "53.358464071105",
        "58.972495339287"
      ],
      "phones": [
        "(3519) 41-30-00",
        " 45-40-01"
      ],
      "contacts": null
    },
    {
      "id": "165",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "90",
      "city_name": "Миасс",
      "address": "г.Миасс, ул.Автозаводцев, д.16",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "55.060591937296",
        "60.107267279762"
      ],
      "phones": [
        " (3513) 55-44-54",
        " 8-9080-78-51-61",
        " 8-9193-30-10-90"
      ],
      "contacts": null
    },
    {
      "id": "169",
      "name": "ООО \"Водомер\" ",
      "image": "",
      "city": "94",
      "city_name": "Новосибирск",
      "address": "г.Новосибирск,ул.Немировича-Данченко, д.137",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "54.973283256044",
        "82.90212146627"
      ],
      "phones": [
        "(383) 239-51-20"
      ],
      "contacts": null
    },
    {
      "id": "184",
      "name": "ООО \"Водомер\" ",
      "image": "",
      "city": null,
      "city_name": "",
      "address": "634006, г.Томск, ул.Пушкина, д.75, оф.109",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "56.499909807146",
        "84.998478182209"
      ],
      "phones": [
        "(3822) 32-90-22"
      ],
      "contacts": null
    },
    {
      "id": "185",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "111",
      "city_name": "Тюмень",
      "address": "г.Тюмень, ул.30 лет Победы, д.27/1, стр.1, оф.214",
      "email": null,
      "site": "www.vodomer74.ru/",
      "map": [
        "57.127161358335",
        "65.567095223873"
      ],
      "phones": [
        "8-982-900-22-88"
      ],
      "contacts": null
    },
    {
      "id": "189",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "115",
      "city_name": "Челябинск",
      "address": "г.Челябинск, ул.Зыкова, д.20",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "55.178729048319",
        "61.449344737434"
      ],
      "phones": [
        "(351) 230-56-66",
        " 774-63-73"
      ],
      "contacts": null
    },
    {
      "id": "193",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": null,
      "city_name": "Иркутск",
      "address": " г.Иркутск, ЖК Лазурный, ул.Байкальская д.202/3, офис 13",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "52.256444323954",
        "104.32436677976"
      ],
      "phones": [
        "(3952) 95-91-50"
      ],
      "contacts": null
    },
    {
      "id": "207",
      "name": "ООО \"Водомер\"",
      "image": "",
      "city": "131",
      "city_name": "Сургут",
      "address": "628404, Ханты-Мансийский автономный округ, г.Сургут, ул.Имени Глухова, д.2, корп.1, оф.308",
      "email": null,
      "site": "vodomer74.ru/",
      "map": [
        "61.274039853151",
        "73.504525779762"
      ],
      "phones": [
        "8-912-990-41-41"
      ],
      "contacts": null
    },
    {
      "id": "143",
      "name": "ООО \"Газагросервис\"",
      "image": "",
      "city": "69",
      "city_name": "Великий Новгород",
      "address": "173016, г. Великий Новгород, ул. Ломоносова, д.15",
      "email": null,
      "site": "",
      "map": [
        "58.538723324415",
        "31.244969275463"
      ],
      "phones": [
        "(8162) 67-85-89"
      ],
      "contacts": null
    },
    {
      "id": "150",
      "name": "ООО \"Газпрофи\"",
      "image": "",
      "city": "76",
      "city_name": "Иваново",
      "address": "153002, г.Иваново, ул.Земляная, д.4/2",
      "email": "s345233@mail.ru",
      "site": "",
      "map": [
        "57.019474623516",
        "40.972589949074"
      ],
      "phones": [
        "(4932) 58-00-04",
        " 58-00-02",
        " 58-00-03"
      ],
      "contacts": null
    },
    {
      "id": "175",
      "name": "ООО \"ДМК\"",
      "image": "",
      "city": "100",
      "city_name": "Псков",
      "address": "180006, г. Псков, ул. Л.Поземского, д.123 А",
      "email": null,
      "site": "",
      "map": [
        "57.842492722534",
        "28.302182686508"
      ],
      "phones": [
        "(8112) 72-27-38"
      ],
      "contacts": null
    },
    {
      "id": "142",
      "name": "ООО \"Полигент\"",
      "image": "",
      "city": "68",
      "city_name": "Благовещенск",
      "address": "675002, Амурская обл., г.Благовещенск, ул.Горького, д.1",
      "email": "poligent@amur.ru",
      "site": "",
      "map": [
        "50.262254838491",
        "127.57704859755"
      ],
      "phones": [
        "(4162) 52-88-52",
        " (4162) 53-55-31"
      ],
      "contacts": null
    },
    {
      "id": "166",
      "name": "ООО \"Русстрой-М\"",
      "image": "",
      "city": "91",
      "city_name": "Мурманск",
      "address": "183034, г.Мурманск, ул.Лобова, д.19",
      "email": null,
      "site": "",
      "map": [
        "69.016937906546",
        "33.09828335582"
      ],
      "phones": [
        "(8152) 22-66-36",
        " 23-17-83",
        " (81555) 7-42-05"
      ],
      "contacts": [
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        }
      ]
    },
    {
      "id": "163",
      "name": "ООО \"Сантехоптторг\"",
      "image": "",
      "city": "88",
      "city_name": "Липецк",
      "address": "398007, г.Липецк, ул.Студеновская, д.187А, а/я 1081",
      "email": "info@lsot.ru",
      "site": "",
      "map": [
        "52.630479515489",
        "39.64954332209"
      ],
      "phones": [
        "(4742) 27-68-20",
        " 27-69-20",
        " 27-28-07",
        " 27-27-99"
      ],
      "contacts": null
    },
    {
      "id": "167",
      "name": "ООО \"ТД БЕТАР\"",
      "image": "",
      "city": "92",
      "city_name": "Набережные Челны",
      "address": "423800, РТ, г.Наб.Челны, пр.Мира, д.4а (9/41) - оф.3",
      "email": "tdbetar@mail.ru",
      "site": "",
      "map": [
        "55.723002851841",
        "52.382618313492"
      ],
      "phones": [
        "(8552) 51-52-01",
        " 51-54-30",
        " 36-55-38"
      ],
      "contacts": null
    },
    {
      "id": "147",
      "name": "ООО \"ТеплоМонтажПроект\"",
      "image": "",
      "city": "73",
      "city_name": "Вологда",
      "address": "160004, г. Вологда, ул.Октябрьская, д.43, оф.4",
      "email": null,
      "site": "",
      "map": [
        "59.221103564782",
        "39.86928032209"
      ],
      "phones": [
        "(8172) 72-96-67",
        " 72-95-56"
      ],
      "contacts": null
    },
    {
      "id": "208",
      "name": "ООО \"Термолюкс-Сибирь\"",
      "image": "",
      "city": "132",
      "city_name": "Чита",
      "address": "670045, г.Улан-Удэ, ул.Амбулаторная, д.1А",
      "email": "tls03@mail.ru, tls75@mail.ru",
      "site": "",
      "map": [
        "51.831397335978",
        "107.62195755886"
      ],
      "phones": [
        "(3022) 710-009"
      ],
      "contacts": null
    },
    {
      "id": "209",
      "name": "ООО \"Термолюкс-Сибирь\"",
      "image": "",
      "city": "133",
      "city_name": "Улан-Удэ",
      "address": "670045, г.Улан-Удэ, ул.Амбулаторная, д.1А",
      "email": "tls03@mail.ru",
      "site": "",
      "map": [
        "51.831058325163",
        "107.6214640324"
      ],
      "phones": [
        "(3012) 55-30-70",
        " 64-58-85"
      ],
      "contacts": null
    },
    {
      "id": "200",
      "name": "ООО \"Технокод\"",
      "image": "",
      "city": "125",
      "city_name": "Махачкала",
      "address": "367013,Республика Дагестан,  г.Махачкала, пр.Гамидова, д.27 В",
      "email": "technocode@mail.ru",
      "site": "",
      "map": [
        "42.964899195075",
        "47.517551008598"
      ],
      "phones": [
        "8-988-293-25-32",
        " 8-928-502-00-25"
      ],
      "contacts": null
    },
    {
      "id": "144",
      "name": "ООО \"Шмель ДВ\"",
      "image": "",
      "city": "70",
      "city_name": "Владивосток",
      "address": "690105, Приморский край, г.Владивосток, ул.Русская, д.87 В\r\nтороговый центр &quot;Багратион&quot;, пав.Сантехника",
      "email": "shmeldv@mail.ru",
      "site": "",
      "map": [
        "43.168663045918",
        "131.935997"
      ],
      "phones": [
        "8-914-712-14-80"
      ],
      "contacts": null
    },
    {
      "id": "174",
      "name": "ООО \"Шмель ДВ\"",
      "image": "",
      "city": "99",
      "city_name": "Петропавловск-Камчатский",
      "address": "690105, Приморский край, г.Владивосток, ул.Бородинская, д.46/50\r\nтороговый центр &quot;Строитель&quot;, павильоны 19 Б, 24 Б",
      "email": "shmeldv@mail.ru",
      "site": "",
      "map": [
        "43.167522404836",
        "131.93560167791"
      ],
      "phones": [
        "8-914-712-14-80"
      ],
      "contacts": null
    },
    {
      "id": "191",
      "name": "ООО \"Шмель ДВ\"",
      "image": "",
      "city": "117",
      "city_name": "Южно-Сахалинск",
      "address": "690105, Приморский край, г.Владивосток, ул.Бородинская, д.46/50\r\nтороговый центр &quot;Строитель&quot;, павильоны 19 Б, 24 Б",
      "email": "shmeldv@mail.ru",
      "site": "",
      "map": [
        "43.167538111236",
        "131.93567677976"
      ],
      "phones": [
        "8-914-712-14-80"
      ],
      "contacts": null
    },
    {
      "id": "159",
      "name": "ООО \"Экватор\"",
      "image": "",
      "city": "84",
      "city_name": "Краснодар",
      "address": "г.Краснодар, ул.Российская, д.63",
      "email": null,
      "site": "",
      "map": [
        "45.052804494304",
        "39.017123004299"
      ],
      "phones": [
        "(861) 274-62-64",
        " факс (861) 274-59-00"
      ],
      "contacts": null
    },
    {
      "id": "179",
      "name": "ООО \"Энергетическая компания\"",
      "image": "",
      "city": "104",
      "city_name": "Санкт-Петербург",
      "address": "195027, г. Санкт-Петербург, ул. Курляндская, д.25, литер 3, пом. 7Н",
      "email": "en.com@mail.ru",
      "site": "",
      "map": [
        "59.912108217574",
        "30.280382949074"
      ],
      "phones": [
        "(812) 495-62-75",
        " 495-62-76"
      ],
      "contacts": null
    },
    {
      "id": "198",
      "name": "ООО \"Энергетическая компания\"",
      "image": "",
      "city": "123",
      "city_name": "Петрозаводск",
      "address": "195027, г.Санкт-Петербург, ул.Курляндская, д.25, литер 3, пом.7Н",
      "email": "en.com@mail.ru",
      "site": "",
      "map": [
        "59.912070503125",
        "30.28050096627"
      ],
      "phones": [
        "(812) 495-62-75",
        " 495-62-76"
      ],
      "contacts": null
    },
    {
      "id": "196",
      "name": "ООО \"ЭнергоИнвест\"",
      "image": "",
      "city": "122",
      "city_name": "Якутск",
      "address": "677001, Республика Саха (Якутия), г.Якутск, ул. Бестужева-Марлинского, д.38",
      "email": null,
      "site": "",
      "map": [
        "62.051435342772",
        "129.75449422884"
      ],
      "phones": [
        "(4112) 21-52-20"
      ],
      "contacts": null
    },
    {
      "id": "210",
      "name": "ООО \"ЭнергоИнвест\"",
      "image": "",
      "city": "134",
      "city_name": "Хабаровск",
      "address": "680006, г.Хабаровск, ул.Краснореченская, 139 (проезд со стороны ул.Ангарская, 2Б)",
      "email": "dv@en-invest.ru",
      "site": "",
      "map": [
        "48.417516073915",
        "135.10745244907"
      ],
      "phones": [
        "(4212) 75-00-50 (факс)",
        " 75-07-75"
      ],
      "contacts": null
    },
    {
      "id": "790",
      "name": "ООО «ТД «ЭнергоИнвест»",
      "image": "",
      "city": "108",
      "city_name": "Тверь",
      "address": "170018, г.Тверь, ул.Красные горки, д.1, литера «А»",
      "email": "ek@cetk-santeh.ru",
      "site": "",
      "map": [
        "56.852539",
        "35.952895"
      ],
      "phones": [
        "(4822) 476-478",
        " +7-911-932-90-11",
        " +7-981-973-46-24"
      ],
      "contacts": [
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        }
      ]
    },
    {
      "id": "170",
      "name": "ООО ПКФ \"БЕТАР-Сибирь\"",
      "image": "",
      "city": "95",
      "city_name": "Омск",
      "address": "644034, г. Омск, ул.Чапаева 71/1, офис 746",
      "email": "mail@betar-siberia.ru",
      "site": "www.betar-siberia.ru/",
      "map": [
        "54.99369173815",
        "73.366228050926"
      ],
      "phones": [
        "(3812) 220-210"
      ],
      "contacts": null
    },
    {
      "id": "173",
      "name": "ООО ПКФ \"ДИСТ\"",
      "image": "",
      "city": "98",
      "city_name": "Пермь",
      "address": "614045, г. Пермь, ул. Окулова, д. 7, офис 2\r\n614105, г.Пермь, п.Новые Ляды, ул.Железнодорожная д.20а",
      "email": "info@pkf-dist.ru",
      "site": "www.dist59.ru",
      "map": [
        "58.01559",
        "56.227611"
      ],
      "phones": [
        "(342) 210-36-38",
        " 206-63-36"
      ],
      "contacts": [
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        },
        {
          "POST": "",
          "NAME": "",
          "PHONE": "",
          "EMAIL": ""
        }
      ]
    },
    {
      "id": "151",
      "name": "ООО ПКФ \"ИСТОК\"",
      "image": "",
      "city": "77",
      "city_name": "Ижевск",
      "address": "426011, Удмуртская Республика, г.Ижевск, ул.10 лет Октября, д.32",
      "email": "sales@istok18.ru",
      "site": "istok18.ru/",
      "map": [
        "56.871632071417",
        "53.227805050926"
      ],
      "phones": [
        "(3412) 72-20-10",
        " 42-08-33",
        " 42-14-45"
      ],
      "contacts": null
    },
    {
      "id": "158",
      "name": "ООО Торговый дом \"Зори Кубани\"",
      "image": "",
      "city": "84",
      "city_name": "Краснодар",
      "address": null,
      "email": null,
      "site": "",
      "map": [
        ""
      ],
      "phones": [
        "(861) 267-03-96"
      ],
      "contacts": null
    },
    {
      "id": "181",
      "name": "ООО Фирма \"БЕТАР-Юг\"",
      "image": "",
      "city": "106",
      "city_name": "Ставрополь",
      "address": "355012, г. Ставрополь, ул.Комсомольская, 1",
      "email": null,
      "site": "",
      "map": [
        "45.043746756145",
        "41.993999038692"
      ],
      "phones": [
        "(8652) 95-03-83"
      ],
      "contacts": null
    },
    {
      "id": "136",
      "name": "ТОО \"СП \"БЕТАР-Азия\"",
      "image": "",
      "city": "60",
      "city_name": "Экибастуз",
      "address": "141206, Республика Казахстан, Павлодарская область,\r\nг.Экибастуз, ул.Абая, д.129",
      "email": "betar_azia@mail.ru",
      "site": "",
      "map": [
        "51.711320902375",
        "75.337859550926"
      ],
      "phones": [
        "8(7187) 34-06-45",
        " 75-89-73",
        " +7-701-520-52-81"
      ],
      "contacts": null
    },
    {
      "id": "201",
      "name": "ТОО СП \"Бетар Азия\"",
      "image": "",
      "city": "63",
      "city_name": "Бишкек",
      "address": "Кыргызская Республика, г.Бишкек, ул.Медерова, д.8",
      "email": "betar_azia@mail.ru, info@teplota.kg",
      "site": "teplota.kg/",
      "map": [
        "42.852276781155",
        "74.610688838623"
      ],
      "phones": [
        "+996 772 32-88-65"
      ],
      "contacts": null
    },
    {
      "id": "203",
      "name": "Торговая компания «ТЕПЛЫЙ ДОМ»",
      "image": "",
      "city": "127",
      "city_name": "Абакан",
      "address": "655004, Хакасия, г.Абакан, ул.Советская, д.148\r\nтел. (3902) 28-51-88\r\n \r\n655018,Хакасия, г.Абакан, ул.Павших Коммунаров, д.141\r\nтел.(3902) 35-40-04\r\n \r\n655004,Хакасия, г.Абакан, ул.Итыгина, д.10\r\nпавильон 05, база «Торговый город» тел. 8-9831-53-36-55\r\nпавильон 8/1, база «Торговый город» тел.(3902) 35-50-50",
      "email": "garant333@mail.ru",
      "site": "Тдом.рф/",
      "map": [
        "53.717956979157",
        "91.407363550926"
      ],
      "phones": [
        ""
      ],
      "contacts": null
    },
    {
      "id": "204",
      "name": "Торговая компания «ТЕПЛЫЙ ДОМ» ",
      "image": "",
      "city": "128",
      "city_name": "Саяногорск",
      "address": "655603,Хакасия, г.Саяногорск, ул.Металлургов, д.25А",
      "email": "garant333@mail.ru",
      "site": "Тдом.рф/",
      "map": [
        "53.104171467062",
        "91.399309508598"
      ],
      "phones": [
        "(39042) 6-46-86"
      ],
      "contacts": null
    },
    {
      "id": "205",
      "name": "Торговая компания «ТЕПЛЫЙ ДОМ»",
      "image": "",
      "city": "129",
      "city_name": "Кызыл",
      "address": "667007, Республика Тыва, г.Кызыл, ул.Оюна Курседи, д.54\r\n«второе АТП», маг. «Теплотехника»",
      "email": "garant333@mail.ru",
      "site": "Тдом.рф/",
      "map": [
        "51.708322910325",
        "94.458403635582"
      ],
      "phones": [
        "8-9083-26-22-40"
      ],
      "contacts": null
    },
    {
      "id": "153",
      "name": "Торговое представительство \"БЕТАР\"",
      "image": "",
      "city": "79",
      "city_name": "Казань",
      "address": "420100, Республика Татарстан, г.Казань, Пр.Победы, 206, оф.3 (БЦ Визит)",
      "email": "betar116@mail.ru",
      "site": "www.shopbetar.ru/",
      "map": [
        "55.803097483241",
        "49.210386728836"
      ],
      "phones": [
        "(843) 524-72-96",
        " факс (843) 524-72-97"
      ],
      "contacts": null
    },
    {
      "id": "197",
      "name": "ЧП Макрушин Роман Сергеевич",
      "image": "",
      "city": "62",
      "city_name": "Ашхабад",
      "address": "Туркменистан,744015,г.Ашхабад, Проезд Труда д.28",
      "email": "makrushin82@gmail.com",
      "site": "",
      "map": [
        "37.982997998942",
        "58.335665883789"
      ],
      "phones": [
        "+99365650598",
        " +99367059501"
      ],
      "contacts": null
    }
];

var map_styles = [
    {
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#2a3f4c"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text",
        "stylers": [
            {
                "weight": 2
            },
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#888c8f"
            },
            {
                "weight": 1.5
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#0079c1"
            },
            {
                "weight": 1
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": 2
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "weight": 2
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2a3f4c"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2a3f4c"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2a3f4c"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#71c5ef"
            }
        ]
    }
];

var mapGoo;
var markersGoogleMap = [];
var imageBlueIcon;
var imageWhiteIcon;
var imageUfa;
function initMap() {
  imageBlueIcon = {
      url: '/img/pointer.png',
      size: new google.maps.Size(32, 49),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 49)
  };
  imageWhiteIcon = {
      url: '/img/pointer-white.png',
      size: new google.maps.Size(31, 47),
      origin: new google.maps.Point(0, 0), 
      anchor: new google.maps.Point(16, 47) 
  };
  imageUfa = {
      url: '/img/pointer-ufa.png',
      size: new google.maps.Size(37, 57),
      origin: new google.maps.Point(0, 0), 
      anchor: new google.maps.Point(18, 57) 
  };
  mapGoo = new google.maps.Map(document.getElementById('map-googl'), {
      center: new google.maps.LatLng(57.8656, 69.3646),
      zoom: 4,
      minZoom: 3,
      scrollwheel: false,
      zoomControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
      styles: map_styles
  });
  addressMap.forEach(function (agent) {
      var item = new google.maps.Marker({
          position: new google.maps.LatLng(agent.map[0], agent.map[1]),
          title: agent.city_name,
          icon: (agent.city_name === "Уфа") ? imageUfa : imageBlueIcon,
          map: mapGoo,
          customId: agent.id
      });
      item.addListener('click', function(e) {
        $('body').trigger('mapMarkerClick', item);
      });
      markersGoogleMap.push(item);
  });

  google.maps.event.addDomListener(window, 'load', initialize);
}



function initialize() {
  var input = document.getElementById('autocompleteAddress');
  var options = {
    types: ['(cities)'],
    language: 'ru'
  };
               
  new google.maps.places.Autocomplete(input, options);
}




angular.module("App")
.factory("mapService", ['$timeout', function ($timeout, $http){
  
  function setCities($scope){
    $scope.cities = addressMap;
  }

  return {
    setCities:setCities
  }
}])


