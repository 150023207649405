angular.module("App")
.service("OrderService", ['DeliveryServise', '$timeout', 'EmailService', 'StatService', function (DeliveryServise, $timeout, EmailService, StatService){

    var order = pricesOrderModel;
    var sliderGlobal;

    function calculatePrice(order, i, $scope) {
        if(order[i] == undefined || !order[i].price || !order[i].current) return 0;
        var price;

        if(angular.isArray(order[i].price)) {
            var reference = order[i].reference;
            price = order[i].price[ order[reference].current ];
        } else {
            price = order[i].price
        }

        var promocodeDiscount = 0;
        if ($scope != undefined && $scope.order != undefined && $scope.order.appliedPromocodeDiscount != undefined) {
            promocodeDiscount = $scope.order.appliedPromocodeDiscount || 0;
        }
        if (order[i].noDiscount === true) {
            promocodeDiscount = 0;
        }

        var discountPrice = price;
        var q = order[i].current.quantity;
        if (q == undefined || q == null) { q = 1; }
        if(price) {
            discountPrice = Math.round(price * (1 - promocodeDiscount)) * q;
            price = price * q;
        }

        if( !price ) return 0;

        return {
            price: price,
            priceWithDiscount: discountPrice
        }

    };

    function calculateComplectation(order, i){
        if( order[i] == undefined || order[i].current === undefined ) return '';
        var current = order[i].current;
        switch(typeof current){
            case 'number':
                return order[i].label + ': '+order[i].items[current];
            case 'boolean':
                return order[i].label + ': 1 шт.';
            default:
                if(current.quantity) return order[i].label + ': '+ current.quantity +' шт.';
        } 
    };

    function preventSlide(){
        sliderGlobal.slick('setIndexCheckoutSlide', 1);
    }

    function setAlertMessage($scope){
        var form = $('#orderform');
        $scope.alertMessage = '';
        var order = $scope.order;

        // check if empty order ==============================================
        if(!$scope.totalPrice){
            $scope.alertMessage = 'Сумма заказа равна нулю';
            preventSlide();
            return;
        }else{
            var isEmptyOrder = true;
            for(var i in $scope.order){
                if($scope.order[i].current && $scope.order[i].label != $scope.order.Dostavka.label){
                    if(typeof $scope.order[i].current == 'boolean' || $scope.order[i].current.quantity){    
                        isEmptyOrder = false;                 
                        break;
                    }                 
                }
            }
            if(isEmptyOrder){
                $scope.alertMessage = 'Ничего не выбрано';
                preventSlide();
                return;
            }     
        }
   

    };

    function adressCalc($scope){
        $scope.fullAddress = $scope.order.form.fullAddress
    };

    function ditailComplectationCalc($scope){
        var orderComplectation = '';
        var result;
        for(var i in $scope.order){
            result = calculateComplectation($scope.order, i);
            if(result) orderComplectation += result + ";\n"; 
            result = '';      
        }
        $scope.orderComplectation = orderComplectation;
    };

    function kassaCalc($scope){
        if( !$scope.order ) return;
        var kassaYandex = {
            customerContact:'',
            items:[]
        };
        if($scope.order.form){
            if($scope.order.form.Phone){
                kassaYandex.customerContact = $scope.order.form.Phone.replace(/[()-\s]/g, '')
            }else{
                kassaYandex.customerContact = $scope.order.form.Email ? $scope.order.form.Email : '';
            }
        }

        var product;
        for(var i in $scope.order){
            product = undefined;
            var price;
            if ($scope.order[i] == undefined || $scope.order[i] == null) continue;
            var current = $scope.order[i].current;
            if( !current || !$scope.order[i].price ) continue;
            if( !current.quantity && current !== true) continue;
  
            if(angular.isArray($scope.order[i].price)) {
                var reference = $scope.order[i].reference;
                price = Math.round($scope.order[i].price[ $scope.order[reference].current ] * (1 - ($scope.order.appliedPromocodeDiscount || 0)));
            } else {
                if ($scope.order[i].noDiscount === true) {
                    price = $scope.order[i].price;
                } else {
                    price = Math.round($scope.order[i].price * (1 - ($scope.order.appliedPromocodeDiscount || 0)));
                }
            }
    
            if( !price ) continue;
                  
            product = {
                quantity:1,
                price:{
                    amount: 0
                },
                tax: 1,
                text: ""
            };

            if(current.quantity){
                product.quantity = current.quantity;
            }
            product.price.amount = price;
            product.text = $scope.order[i].label;

            if($scope.order[i].infoForCheck){
                switch( $scope.order[i].infoForCheck ){
                    case 'Diameter':
                        product.text += ', ' + $scope.order.Diameter.items[$scope.order.Diameter.current];
                        break;
                    case 'MaterialColor':               
                        var material = $scope.order.Oformlenie.items[$scope.order.Oformlenie.current];
                        var color = $scope.order.Cvet.items[$scope.order.Cvet.current];  
                        product.text += ` (${material}, ${color})`;
                        break;
                }
            }

            if( product ) kassaYandex.items.push(product);     
        }
        
        if( !kassaYandex.customerContact || !kassaYandex.items.length){
            $scope.kassaYandex = '';
            return;
        }
        
        $scope.kassaYandex = JSON.stringify(kassaYandex);
    };



    function checkEmptyFields($scope){
        var err = $scope.orderform.$error;
        var isThereErrors = false;
        for(var i in err){
            err[i].forEach((item) => {
                isThereErrors = true;
                item.$setTouched()
                if($scope.shakingInput[item.$name]) $scope.shakingInput[item.$name]();
            })
        }
        return isThereErrors;
    }

    function buy($scope, $event){
        $scope.localUserId = StatService.getLocalUserId();
        $scope.order.cubyhomeLandingUserId = StatService.getLocalUserId();
        $scope.agentName = StatService.getAgentName();
        $scope.order.agentName = StatService.getAgentName();

        if(checkEmptyFields($scope)){
            $event.preventDefault();
            $('html, body').animate({
                scrollTop: $('.final-order-form-left').offset().top
            }, 500);
            return;
        };
        if($scope.alertMessage){
            $event.preventDefault();
            var wind = $('#modal-alert');
            wind.find('p').text($scope.alertMessage);
            $('#modal-alert').modal('show');
            return;
        }
        StatService.registerUserAction(1);
        EmailService.send($scope.order);
        $scope.loadingBuy.active = true; 
    };

    // function buyTest($scope, $event){
    //     $event.preventDefault();
    //     EmailService.send($scope.order);
    // }

    function totalPrice($scope, newVal){
        $scope.totalPrice = 0;
        $scope.totalPriceCorrected = 0;

        for(var i in newVal){
            var result = calculatePrice(newVal, i, $scope);
            if(!result){
                $scope.totalPrice += 0;
                $scope.totalPriceCorrected += 0;
            }else{
                $scope.totalPrice += result.price; 
                $scope.totalPriceCorrected += result.priceWithDiscount; 
            }          
        }

        //$scope.totalPriceCorrected = Math.round($scope.totalPriceCorrected);

        $scope.totalPriceFormatted = $scope.formatPrice($scope.totalPrice);
        $scope.totalPriceCorrectedFormatted = $scope.formatPrice($scope.totalPriceCorrected);
    }

    function watchOrder($scope){
        // change max of all if change Aqua quantity
        $scope.$watch('order.Aqua.current.quantity', function(newVal, oldVal){
            if( newVal === undefined ) return;
            changeMaxQuantityDependOnAqua($scope);
        });

        $scope.$watch('order', function(newVal, oldVal){
            sliderGlobal && sliderGlobal.slick('setIndexCheckoutSlide', false);
            adressCalc($scope);
            ditailComplectationCalc($scope);
            totalPrice($scope, newVal);
            setAlertMessage($scope);
            kassaCalc($scope);
            calcTotalMassa($scope);
        }, true);
    };

    function calcTotalMassa($scope){
        let order = $scope.order;
        let massa = 0;
        for(let key in order){
            let item = order[key];
            if( item == undefined || item == null || !item.massa || !item.current ) continue;
            let quantity = (item.current === true) ? 1 : item.current.quantity;
            massa += item.massa * quantity;
        }
        $scope.totalMassaOfOrder = massa;
    }

    function watchDeliveryPrice($scope){
        $scope.$watch('updateDeliveryPrice', function(newVal, oldVal){
            var price = $scope.deliveryData.punctsInView.deliveryPrice;
            if( !price ){
                $scope.order.Delivery.price = 0;
            }else{
                var maxPrice = $scope.order.Delivery.limit * $scope.totalPriceCorrected;
                if( price > maxPrice ){
                    $scope.order.Delivery.price = Math.ceil(price - maxPrice);
                    console.log('price del '+price+' - '+maxPrice+' = ', $scope.order.Delivery.price);
                }else{
                    $scope.order.Delivery.price = 0;
                }
            }
        });
    }

    function changeMaxQuantityDependOnAqua($scope){
        var aqua = $scope.order.Aqua.current.quantity;
        var times;
        if( aqua === undefined ) return;
        if( !aqua ){
            times = 1;
        } else {
            times = aqua;
        }
        for(var key in $scope.order){
            if( key === 'Aqua' ) continue;
            var el = $scope.order[key];
            if( !el.current || el.current.max === undefined ) continue;
            if( el.current.max !== undefined && el.current.maxInitial === undefined ) {
                el.current.maxInitial = el.current.max;
            }
            el.current.max = el.current.maxInitial * times;
            if( el.current.quantity > el.current.max ) {
                el.current.quantity = el.current.max;
            }
        }
    }

    function getPages(){
        return [{active: true,
                    completed: false},
                    {active: false,
                    completed: false},
                    {active: false,
                    completed: false}];
    };

    function getOrder(){
        return order;
    };

    function initSlider(){
        sliderGlobal = $('.steps-order').slick({
            dots: false,
            arrows:false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable:false,
            indexCheckoutSlide:false,
            adaptiveHeight: false,
            fade: true
        });
        return sliderGlobal;
    };

    // function setDelivery(order, nextSlide){
    //     if(nextSlide >= 2){
    //         order.Dostavka.current = order.Dostavka.items[1];
    //         // order.Dostavka.switchItem = true;
    //     } else {
    //         order.Dostavka.current = order.Dostavka.items[0];
    //     }
    // };

    function pageClassName($scope, nextSlide){
        $scope.pages.map(function(el, i){
            if(i < nextSlide){
                el.active = true;
                el.completed = true;
                return el;
            } 
            if(i > nextSlide){
                el.active = false;
                el.completed = false;
                return el;
            } 
            if(i == nextSlide){
                el.active = true;
                el.completed = false;
                return el;
            };                
        });
    }

    function yandexChangeSlide(nextSlide) {
        if (yaCounter45087543 == undefined) { return; }
        switch(nextSlide){
            case 0:
                // console.log(nextSlide)
                break;
            case 1:
                yaCounter45087543.reachGoal('goToAppearance');
                break;
            case 2:
                yaCounter45087543.reachGoal('goToData');
                break;
        }
    }

    function onPageChange($scope, slider){
        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){ 
            if(currentSlide === nextSlide) return;
            yandexChangeSlide(nextSlide);
            $scope.$evalAsync(function(){
                // setDelivery($scope.order, nextSlide);
                pageClassName($scope, nextSlide);   
            });               
        });

        slider.on('preventSlidingCheckoutSlide', function(){
                $scope.alertMessage = 'Ничего не выбрано';
                var wind = $('#modal-alert');
                wind.find('p').text($scope.alertMessage);
                wind.modal('show'); 
        });

    };

    function go(event, distination, slider){
        if (event != undefined) { event.preventDefault(); }
        switch(typeof distination){
            case 'number':
                slider.slick('slickGoTo', distination);
                break;
            case 'string':
                if(distination === 'prev'){
                    slider.slick('slickPrev');
                }
                break;
            default:
                slider.slick('slickNext');
        };
    };

    function deliveryInit($scope){
        // get delivery data only when start typeing address

        var delivery = new DeliveryServise($scope);
        delivery.addWatchFullAddress();
    }

    

    

    

    return {
        getPages: getPages,
        getOrder: getOrder,
        initSlider: initSlider,
        onPageChange: onPageChange,
        go: go,
        watchOrder: watchOrder,
        watchDeliveryPrice: watchDeliveryPrice,
        buy: buy,
        deliveryInit: deliveryInit,
    }
}])