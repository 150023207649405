var pricesOrderModel = {
    form:{},
    Diameter:{
        label: 'Диаметр подключения',
        current: 0, // index in items
        items:['1/2 д (15 мм)', '3/4 д (20 мм)']
    },
    Oformlenie:{
                label:"Корпус",
                current: 0, // index in items
                items:["Пластик","Алюминий"]
    },       
    Cvet:{
                label:"Цвет",
                current: 0, // index in items
                items:["Белый", 'Черный']
    },   
    Dostavka:{
                label:"Доставка",
                current: 0, // index in items
                items:["Boxberry", "СДЭК"]
    },    
    Delivery:{// if higher then 10% of order price, the difference will be include in check
                label:"Оплата доставки до пункта выдачи. Оплата на сумму, превышающую 10% от стоимости заказа",
                limit: 0.1, // max delivery price, that cubyhome can pay
                current: true, 
                price: 0,
                noDiscount: true
    },    
    Connect:{
                label:"Базовая станция CONNECT",
                infoForCheck:'MaterialColor',
                current:true,
                reference: 'Oformlenie',
                price: [3500, 6500],
                massa: 500, // грамм
                discount:0
    },       
    Aqua:{
                label:"Модуль управления AQUA",
                infoForCheck:'MaterialColor',
                current:{
                    quantity: 1
                },
                reference: 'Oformlenie',
                price: [6000, 9000],
                massa: 500,
                discount:0
    },       
    Betar:{
                label:"Импульсные счетчики воды",
                infoForCheck:'Diameter',
                reference:'Diameter',
                current:{
                    quantity: 2,
                    max:2
                },
                price: [900, 1200],
                massa: 500,
                discount:0
    },       
    Zadvizhki:{
                label:"Моторизированные задвижки",
                infoForCheck:'Diameter',
                reference:'Diameter',
                current:{
                    quantity: 2,
                    max:4
                },
                price: [3500, 3900],
                massa: 500,
                discount:0
    },       
    Protechka:{
                label:"Датчики протечки",
                current:{
                    quantity: 4,
                    max:4
                },
                price: 750,
                massa: 10,
                discount:0
    }
};