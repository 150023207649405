angular.module("App")
.factory("DeliveryServise", ['$timeout', '$http', function ($timeout, $http){


    // === Constructor ===============================================================
    function DeliveryFunc($scope){
        this.$scope = $scope;
        this.$scope.loadingPunct = {
            active:false, 
            success:false
        };
        this.$scope.deliveryData = {
            punctsInView: { 
                selected:null,
                items:[], // ng-options
                deliveryPrice: undefined
            }, 
            Boxberry:{ // type delivery
                cities:[],
                punctsBySityCode:{},
                priceBySityCode:{}
            },
            "СДЭК":{ // type delivery
                cities:[],
                punctsBySityCode:{},
                priceBySityCode:{}
            }
        }
    }

    DeliveryFunc.prototype.resetPunctsInView = function(){
        if( !this.$scope.deliveryData.punctsInView.selected ) return;
        this.$scope.deliveryData.punctsInView.selected = null;
        this.$scope.deliveryData.punctsInView.items = [];
        this.$scope.deliveryData.punctsInView.deliveryPrice = undefined;
        this.$scope.$evalAsync( () => {} );
    }

    DeliveryFunc.prototype.addWatchFullAddress = function(){
        var $scope = this.$scope;
        var self = this;

        $scope.$watch('order.form.fullAddress', function(newVal, oldVal){
            if(newVal && newVal.length > 0){
                
                // addWatchDeliveryType and totalMassaOfOrder
                if( !self.isWatchDeliveryType ){
                    self.isWatchDeliveryType = true;
                    self.addWatchDeliveryType();
                    self.addWatchMassa();
                } 

                self.getCities();
                self.selectCity();
            }   
        });


        // $scope.$watch('deliveryData', function(newVal, oldVal){
        //     console.log('data ',newVal);
        // }, true);
    }


    DeliveryFunc.prototype.addWatchMassa = function(){
        // instead of watch all order. For getting delivery price
        this.$scope.$watch( 'totalMassaOfOrder', (newVal, oldVal) => {
            if( !newVal ) return;
            // this.requstDeliveryCostAfterSomeChanges('forbidTakeOldPrice');
            this.requstDeliveryCostAfterSomeChangesDebounced('forbidTakeOldPrice');
        });
    }


    DeliveryFunc.prototype.addWatchDeliveryType = function(){
        var $scope = this.$scope;
        var self = this;

        $scope.$watch('order.Dostavka.current', function(newVal, oldVal){
            if( newVal === oldVal ) return;
            self.getCities();
            self.selectCity();
        });
    }

    DeliveryFunc.prototype.getCities = function(){
        var $scope = this.$scope;
        var typeDelivery = $scope.order.Dostavka.items[$scope.order.Dostavka.current];
        if( $scope.deliveryData[typeDelivery].cities.length > 0 ) return;

        if(typeDelivery==="Boxberry"){
            this.getBoxberryCities($scope.deliveryData[typeDelivery]);
        }else{
            this.getSDEKCities($scope.deliveryData[typeDelivery]);
        }
    }

    DeliveryFunc.prototype.getSDEKCities = function(delivery){
        // load script, not API request
        var self = this;
        if( self.blockGetSDEKCities === true ) return;
        self.blockGetSDEKCities = true;

        var sdekData = "js-dist/sdek-5c69b306ba603301447a47aaf5deb9e4.js";

        jQuery.cachedScript = function( url, options ) {       
            options = $.extend( options || {}, {
            dataType: "script",
            cache: true,
            url: url
            });
            return jQuery.ajax( options );
        };
            
        // Usage
        $.cachedScript( sdekData ).done(function( script, textStatus ) {
            delivery.cities = window.sdekCities;
            self.selectCity();
        });

      
    }


    DeliveryFunc.prototype.getBoxberryCities = function(delivery){
        if( this.blockGetCities === true ) return;
        this.blockGetCities = true;
        var self = this;

        setTimeout( () => {
            if(self.blockGetCities === true) self.blockGetCities = false;
        }, 3000);

        $.ajax({
            type: "POST",
            url: 'boxberry.php',
            data: {
                method:'getCities',
                type: 'Boxberry'
            },
            success: function(res){
                if( !res ) return;
                res = JSON.parse(res);
                if( !angular.isArray(res) ) return;
                delivery.cities = res;
                self.selectCity();
                self.blockGetCities = false;
            },
            error: err => {
                console.log(err);
                self.blockGetCities = false;
            }
        });

    }

    DeliveryFunc.prototype.requestPuncts = function(city){
        var $scope = this.$scope;
        var self = this;
        var requstDeliveryCostAfterSomeChanges = this.requstDeliveryCostAfterSomeChanges.bind(this);

        return new Promise( function(resolve, reject) {
    
            var typeDelivery = $scope.order.Dostavka.items[$scope.order.Dostavka.current];
            var puncts = $scope.deliveryData[typeDelivery].punctsBySityCode;
            var pricesDelivery = $scope.deliveryData[typeDelivery].priceBySityCode;
    
            if ( !city.Code && city.ID ) {
                city.Code = city.ID;
            }
    
            if( puncts[city.Code] ){
                $scope.deliveryData.punctsInView.items = puncts[city.Code];
                $scope.deliveryData.punctsInView.selected = $scope.deliveryData.punctsInView.items[0];
                // $scope.deliveryData.punctsInView.deliveryPrice = pricesDelivery[city.Code];
                requstDeliveryCostAfterSomeChanges('forbidTakeOldPrice');
                $scope.$evalAsync( () => { })
                return;
            }
    
            var data = {Code: city.Code};
            data.method = 'getPuncts';
            data.type = (typeDelivery === 'СДЭК') ? 'SDEK' : typeDelivery;
    
            self.showLoadingIndicator();
    
            $.ajax({
                type: "POST",
                url: 'boxberry.php',
                data: data,
                success: function(res){
                    $scope.$evalAsync(function(){
                        resolve({
                            res:res, 
                            puncts:puncts, 
                            pricesDelivery:pricesDelivery,
                            city:city, 
                            typeDelivery:typeDelivery
                        })
                        self.hideLoadingIndicator();
                    })
                },
                error: err => {
                    reject( err );
                }
            });
        })
    }

    DeliveryFunc.prototype.successRequestPuncts = function(par){
        if( !par || !par.typeDelivery ) return;
        if(par.typeDelivery === 'СДЭК'){
            return this.parseGetSDEKpuncts(par);
        }else{
            return this.parseGetBoxberryPuncts(par);
        }
    }


    DeliveryFunc.prototype.parseGetSDEKpuncts = function(par){
        if( !par.res ) return;

        var res = $.parseXML(par.res);
        res = $(res).find('Pvz');
        if( !res.length ) return;

        var resArr = [];
        res.each(function(el){
            var city = {};
            var that = $(this);
            city.AddressReduce = that.attr('Address');
            city.CityName = that.attr('City');
            city.Area = that.attr('RegionName');
            resArr.push(city);
        })

        if( !resArr.length ) return;

        par.res = resArr;
        return this.afterSuccessGetPuncts(par);

        // par.puncts[par.city.Code] = resArr.map( punct => {
        //     punct.cityId = par.city.Code;
        //     return punct;
        // });

        // this.$scope.$evalAsync( () => { 
        //     this.$scope.deliveryData.punctsInView.items = resArr;
        //     this.$scope.deliveryData.punctsInView.selected = resArr[0];
        //     console.log('SDEK resp.', resArr);
        // })

        // return {
        //     punct: resArr[0],
        //     typeDelivery: par.typeDelivery,
        //     city: par.city
        // }
        
    }
    
    DeliveryFunc.prototype.parseGetBoxberryPuncts = function(par){
        if( !par.res ) return;

        var res = JSON.parse(par.res);
        if( !angular.isArray(res) ) return;

        par.res = res;
        return this.afterSuccessGetPuncts(par);
    }
    
    DeliveryFunc.prototype.afterSuccessGetPuncts = function(par){

        //save puncts to punctsBySityCode
        par.puncts[par.city.Code] = par.res.map( punct => {
            punct.cityId = par.city.Code;
            return punct;
        });

        this.$scope.deliveryData.punctsInView.items = par.res;
        this.$scope.deliveryData.punctsInView.selected = par.res[0];
        console.log(
            (par.typeDelivery === 'СДЭК') ? 'СДЭК resp.' : 'Boxb. resp.', 
            par.res
        );
    
        // this.$scope.$evalAsync( () => {
        // })
        par.punct = par.res[0];
    
        return par;
    }

    DeliveryFunc.prototype.calculateMassa = function(){
        var $scope = this.$scope;
        var self = this;
        var order = $scope.order;
        var massa = 0;

        for(var key in order){
            if( !order[key].massa ) continue;
            if(order[key].current === true) {
                massa += order[key].massa;
                continue;
            }
            if( order[key].current.quantity ) {
                massa += order[key].massa * order[key].current.quantity;
            }
        }

        return massa;
    }

    DeliveryFunc.prototype.simpleCalc = function(el){
        var order = this.$scope.order;
        var device = el.inOneBox;
        var quantity = 0;

        if( typeof order[device].current === 'boolean' ){
            quantity = order[device].current ? 1 : 0;
        } else {
            quantity = order[device].current.quantity;
        }

        el.quantityBoxes = Math.ceil( quantity / el[el.inOneBox].maxPerBox );
    }

    DeliveryFunc.prototype.byMaxCalc = function(el){
        var order = this.$scope.order;
        var devices = el.inOneBox;
        var maxArr = [];

        devices.forEach( (dev) => {
            var quantity = 0;
            if( typeof order[dev].current === 'boolean' ){
                quantity = order[dev].current ? 1 : 0;
            } else {
                quantity = order[dev].current.quantity;
            }
            maxArr.push(Math.ceil(quantity / el[dev].maxPerBox));
        })

        el.quantityBoxes = Math.max.apply(null, maxArr);
    }

    DeliveryFunc.prototype.additivityCalc = function(el){
        var order = this.$scope.order;
        var devices = el.inOneBox;
        var quantity = 0;

        devices.forEach( (dev) => {
            var localQuantity;
            if( typeof order[dev].current === 'boolean' ){
                localQuantity = order[dev].current ? 1 : 0;
            } else {
                localQuantity = order[dev].current.quantity;
            }
            quantity += localQuantity;
        })

        el.quantityBoxes = Math.ceil( quantity / el.additivity.maxPerBox );
    }

    DeliveryFunc.prototype.calculateBox = function(data){
        // 1 коробка на 1 aqua
        // 1 коробка на 1 connect
        // 1 коробка на 4 аксессуара (счетчики и задвижки)
        // датчики протечки кладутся в акву (4 в коробку)
        // В стопке из коробок соединеннных скотчем может быть максимум 5 коробок
        // Я буду суммировать высоты коробок бесконечно
        data.width = 21;
        data.depth = 21;
        var quantityOfBoxes = 0;
        var heightOne = 11;

        var self = this;
        var order = this.$scope.order;

        var box = [
            {
                inOneBox: 'Connect',
                calcSchema:'simple',
                Connect: {maxPerBox: 1},
                quantityBoxes:0
            },
            {
                inOneBox: ['Aqua', 'Protechka'],
                calcSchema:'byMax',
                Aqua: {maxPerBox: 1},
                Protechka: {maxPerBox: 4},
                quantityBoxes:0
            },
            {
                inOneBox: ['Betar', 'Zadvizhki'],
                calcSchema:'additivity',
                additivity: {maxPerBox: 4},
                Betar: {},
                Zadvizhki: {},
                quantityBoxes:0
            }
        ];    

        box.forEach( (el, index) => {
            switch(el.calcSchema){
                case'simple':
                    self.simpleCalc(el);
                    break;
                case'byMax':
                    self.byMaxCalc(el);
                    break;
                case'additivity':
                    self.additivityCalc(el);
                    break;
            }
        });

        var totalBoxQuantity = box.reduce( (accumulator, current) => (accumulator + current.quantityBoxes), 0 );
        data.height = (totalBoxQuantity * heightOne) || heightOne;
    }

    DeliveryFunc.prototype.requstDeliveryCostAfterSomeChanges = function(forbidTakeOldPrice){
        if( !this.$scope.deliveryData.punctsInView.selected ) return;

        var typeDelivery = this.$scope.order.Dostavka.items[this.$scope.order.Dostavka.current];
        var punct = this.$scope.deliveryData.punctsInView.selected;
        var data = {
            punct: punct,
            typeDelivery: typeDelivery,
            city:{ Code:punct.cityId }
        }

        if(forbidTakeOldPrice) data.forbidTakeOldPrice = forbidTakeOldPrice;
        
        this.requstDeliveryCost(data)
        .then( this.successDeliveryPrice.bind(this) )
        .catch( err => console.log(err));
    };

    DeliveryFunc.prototype.requstDeliveryCostAfterSomeChangesDebounced = myLib.debounce( function(forbidTakeOldPrice){
        this.requstDeliveryCostAfterSomeChanges(forbidTakeOldPrice);
    }, 
    1000);


    DeliveryFunc.prototype.requstDeliveryCost = function(par){
        var $scope = this.$scope;
        var self = this;
        var priceSaved = $scope.deliveryData[par.typeDelivery].priceBySityCode[par.city.Code];

        if( priceSaved && !par.forbidTakeOldPrice ){
            $scope.deliveryData.punctsInView.deliveryPrice = priceSaved;
            $scope.$evalAsync(function(){ });
            return Promise.resolve(false);
        }

        // Входящие параметры Boxberry:
            // weight - вес посылки в граммах,
            // target - код ПВЗ,
            // Обратите внимание! Следующие поля считаются равными 0 если не заполнены.,
            // ordersum - cтоимость товаров без учета стоимости доставки,
            // deliverysum - заявленная ИМ стоимость доставки,
            // targetstart - код пункта приема посылок,
            // height - высота коробки (см),
            // width - ширина коробки (см),
            // depth - глубина коробки (см),
            // zip - индекс города для курьерской доставки
            // paysum - сумма к оплате     
        // Входящие параметры SDEK:  
            // weight - вес посылки в кг,

        var data = {};
        data.target = (par.typeDelivery === 'СДЭК') ? par.city.Code : par.punct.Code;
        data.weight = self.calculateMassa();
        if( par.typeDelivery === 'СДЭК' ){
            data.weight = data.weight / 1000;
        }
        self.calculateBox(data);
        data.method = 'getPrice';
        data.type = (par.typeDelivery === 'СДЭК') ? 'SDEK' : par.typeDelivery;
  
        return new Promise( (resolve, reject) => {
            $.ajax({
                type: "POST",
                url: 'boxberry.php',
                data: data,
                success: function(res){
                    par.res = res;
                    $scope.$evalAsync(function(){ resolve(par) })
                },
                error: err => {
                    reject( err );
                }
            });
        })
    }

    DeliveryFunc.prototype.successDeliveryPrice = function(par){
        if(!par.res) return;
        try {
            par.res = JSON.parse(par.res);
        } catch(err) {
            alert('Не удается связаться с службой доставки')
        }

        if( par.res.error ){
            if (Array.isArray(par.res.error)) {
                let text = par.res.error.reduce((accumulator, current) => (accumulator + current.text), '');
                alert(text);
            } else {
                alert(par.res.error);
            }
        } 
        // alert('не удается получить цену доставки');

        this.$scope.$evalAsync( () => {} );

        if(par.res.price){
            this.$scope.deliveryData.punctsInView.deliveryPrice = parseInt(par.res.price, 10);
        }else if(par.res.result && par.res.result.price){
            this.$scope.deliveryData.punctsInView.deliveryPrice = parseInt(par.res.result.price, 10);
        }else{
            this.$scope.deliveryData.punctsInView.deliveryPrice = 0;
        }
        
        console.log( this.$scope.deliveryData.punctsInView.deliveryPrice );

        // save puncts to priceBySityCode
        this.$scope.deliveryData[par.typeDelivery].priceBySityCode[par.city.Code] = this.$scope.deliveryData.punctsInView.deliveryPrice;
        // par.pricesDelivery[par.city.Code] = this.$scope.deliveryData.punctsInView.deliveryPrice;

        this.$scope.updateDeliveryPrice = this.$scope.updateDeliveryPrice ? ++this.$scope.updateDeliveryPrice : 1;
        
    }

    DeliveryFunc.prototype.selectCity = function(){
        var $scope = this.$scope;
        var self = this;
        var input = $scope.order.form.fullAddress;

        self.resetPunctsInView();

        if( !input || input.length < 3) return;
        input = input.toLowerCase();

        if( !/,/.test(input) ) return;
        var address = input.split(',');

        var typeDelivery = $scope.order.Dostavka.items[$scope.order.Dostavka.current];
        var cities = $scope.deliveryData[typeDelivery].cities;
        if( cities.length === 0 ){
            this.showLoadingIndicator();
            return;
        } else {
            this.hideLoadingIndicator();
        }

        var search = [];
        cities.forEach( (city) => {
            if(city.Name.toLowerCase() === address[0]) search.push(city);
        });
        if(search.length === 0) return;
        var result = search.find( (item) => {
            var region = item.Region ? item.Region.split(' ') : '';
            region = region[0].toLowerCase();
            if( !region ) return true;
            if( input.indexOf(region) !== -1){
                return true;
            }else{
                return false;
            }
        })

        if( result ){
            self
                .requestPuncts(result)
                .then(self.successRequestPuncts.bind(this))
                .then(self.requstDeliveryCost.bind(this))
                .then(self.successDeliveryPrice.bind(this))
                .catch( err => console.log(err));
        }

    }
    
    DeliveryFunc.prototype.showLoadingIndicator = function(){   
        if( this.$scope.loadingPunct.active ) return;
        this.$scope.$evalAsync(() => {
            this.$scope.loadingPunct.active = true;
        });
    }

    DeliveryFunc.prototype.hideLoadingIndicator = function(){ 
        if( !this.$scope.loadingPunct.active ) return;  
        this.$scope.$evalAsync(() => {
            this.$scope.loadingPunct.active = false;
        });
    }





    return DeliveryFunc;


}])