angular.module('App', ['ngAnimate', 'ui.mask'])
.controller('OrderController', ["$scope", "$timeout", "$window", "OrderService", "mapService", "EmailService", function($scope, $timeout, $window, OrderService, mapService, EmailService) {
    var slider;
    $scope.pages = OrderService.getPages(); // need in template
    $scope.order = OrderService.getOrder();
    $scope.shakingInput = {};
    $scope.Math = window.Math;

    $scope.set = {};
    $scope.set.diameter = 0;
    $scope.set.risersCount = 1;
    $scope.set.onlyColdWater = false;
    $scope.set.current = null;

    $scope.recaptchaKey = "6LfseFIUAAAAADmTsoWQVepO2_8DYDtceAUFTFzl";
    $scope.sendMailDisabled = false;

    $scope.getDeviceLabel = function(device) {
        var result = pricesOrderModel[device].label;
        if (device == "Zadvizhki" || device == "Betar") {
            result += " " + pricesOrderModel.Diameter.items[$scope.set.diameter];
        }
        return result;
    }

    $scope.selectSet = function(event, newSet) {
        $scope.set.current = $scope.sets[newSet].set;

        $scope.order.Connect.current = true;
        $scope.order.Diameter.current = $scope.set.diameter;
        for (var orderItemName in $scope.order) {
            var orderItem = $scope.order[orderItemName];
            if (orderItem.current != undefined && orderItem.current.quantity != undefined) {
                var currentQuantity = $scope.set.current[orderItemName];
                if (currentQuantity == undefined) {
                    orderItem.current.quantity = 0;
                } else {
                    orderItem.current.quantity = currentQuantity;
                }
            }
        }

        $scope.go(event, 2);
    }

    $scope.goBackToSetsOrConfig = function(event) {
        if ($scope.set.current == null) {
            $scope.go(event, 'prev');
        } else {
            $scope.set.current = null;
            $scope.go(event, 0);
        }
    }

    $scope.setDiameter = function(newVal) {
        $scope.set.diameter = newVal;
        $scope.order.Diameter.current = newVal;
        $('#buy-block .second-step .dropdownPlugin span.selected').text(pricesOrderModel.Diameter.items[newVal]);
    }

    var originalSets = {
        fullAuto: {
            Connect: 1,
            Aqua: 1,
            Zadvizhki: 1,
            Betar: 1,
            Protechka: 4
        },
        leakageProtection: {
            Connect: 1,
            Aqua: 1,
            Zadvizhki: 1,
            Protechka: 4
        },
        flowControl: {
            Connect: 1,
            Aqua: 1,
            Zadvizhki: 1
        },
        usageReport: {
            Connect: 1,
            Aqua: 1,
            Betar: 1
        }
    }

    $scope.sets = {
        fullAuto: {
            price: 0,
            set: {
                Connect: 1,
                Aqua: 1,
                Zadvizhki: 2,
                Betar: 2,
                Protechka: 4
            }
        }
    };

    $scope.$watch('set', function(newVal, oldVal) {
        $scope.sets = {};
        for (var _set in originalSets) {
            $scope.sets[_set] = {};
            var thisSet = $scope.sets[_set];
            thisSet.set = {};
            thisSet.price = pricesOrderModel["Connect"].price[0];

            for (var device in originalSets[_set]) {
                thisSet.set[device] = originalSets[_set][device];
                if (device == "Connect") { 
                    continue; 
                }

                thisSet.set[device] *= $scope.set.risersCount;
                if (!$scope.set.onlyColdWater && device != "Protechka" && device != "Aqua") {
                    thisSet.set[device] *= 2;
                }

                var thisDevicePrice = pricesOrderModel[device].price;
                if (Array.isArray(thisDevicePrice)) {
                    if (pricesOrderModel[device].reference == "Diameter") {
                        thisSet.price += thisDevicePrice[$scope.set.diameter] * thisSet.set[device];
                    } else {
                        thisSet.price += thisDevicePrice[0] * thisSet.set[device];
                    }
                } else {
                    thisSet.price += thisDevicePrice * thisSet.set[device];
                }
            }
        }
    }, true);

    $timeout(function(){ 
        // fade in blocks
        $scope.BlockVisible = true;
    }, 1000);

    OrderService.watchOrder($scope);
    OrderService.watchDeliveryPrice($scope);
    OrderService.deliveryInit($scope);

    $timeout(function(){ 
        // page slider init-----------------------
        slider = OrderService.initSlider();

        // on page change --------------------------
        OrderService.onPageChange($scope, slider);
    });

    $scope.go = function(event, distination) {
        var orderformTop = $('#orderform').offset().top;
        var viewportTop = $(window).scrollTop();
        if (viewportTop > orderformTop) {
            $('html, body').animate({
                scrollTop: orderformTop
            }, 300);
        }
        OrderService.go(event, distination, slider) 
    };
    
    $scope.formatPrice = function(input){
        return myLib.formatPrice(input);
    }

    $scope.openModal = myLib.openModal;

    var recaptchaQuestion;
    var recaptchaConsult;

    function initRecaptcha() {
        // recaptchaQuestion = $window.grecaptcha.render( "recaptchaQuestion", {
        //     "sitekey" : $scope.recaptchaKey,
        //     "callback": "sendEmailQuestion",
        //     "size": "invisible",
        //     "badge": "inline"
        // });

        // recaptchaConsult = $window.grecaptcha.render( "recaptchaConsult", {
        //     "sitekey" : $scope.recaptchaKey,
        //     "callback": "sendEmailConsult",
        //     "size": "invisible",
        //     "badge": "inline"
        // });
    }

    if ($window.grecaptcha != undefined) {
        initRecaptcha();
    }
    $window.onloadRecaptchaCallback = function() {
        initRecaptcha()
    };
    
    $scope.checkEmailQuestion = function($event) {
        $event.preventDefault();

        var invalidMail = !myLib.emailValidate($scope.qMailEmail);
        if ($scope.qMailEmail == "" || $scope.qMailEmail == undefined || invalidMail) {
            $scope.shakingInput["qMailEmailField"]();
        }
        if ($scope.qMailBody == "" || $scope.qMailBody == undefined) {
            $scope.shakingInput["qMailBodyField"]();
        }
        if ($scope.qMailEmail == "" || $scope.qMailEmail == undefined || $scope.qMailBody == "" || $scope.qMailBody == undefined || invalidMail) {
            return;
        }

        $window.grecaptcha.execute(recaptchaQuestion);
    }

    $scope.sendEmailQuestion = function(token) {
        if (token == undefined || token == null || token == "") {
            myLib.openModal(null, "mail-error");
            return;
        }

        console.log("You wrote: " + $scope.qMailName + "; " + $scope.qMailEmail + "; " + $scope.qMailBody);
        
        $scope.sendMailDisabled = true;
        EmailService.sendQuestion($scope.qMailName, $scope.qMailEmail, $scope.qMailBody, token, function(isSuccess) {
            if (isSuccess) {
                myLib.openModal(null, "mail-sent");
                $scope.qMailName = "";
                $scope.qMailEmail = "";
                $scope.qMailBody = "";
            } else {
                myLib.openModal(null, "mail-error");
            }
            $window.grecaptcha.reset(recaptchaQuestion);
            $scope.sendMailDisabled = false;
        });
    }
    $window.sendEmailQuestion = $scope.sendEmailQuestion;

    $scope.checkEmailConsult = function($event) {
        $event.preventDefault();
        /*
        var invalidMail = !myLib.emailValidate($scope.consultMailEmail);
        if ($scope.consultMailEmail == "" || $scope.consultMailEmail == undefined || invalidMail) {
            $scope.shakingInput["cMailEmailField"]();
        }
        if ($scope.consultMailBody == "" || $scope.consultMailBody == undefined) {
            $scope.shakingInput["cMailBodyField"]();
        }
        if ($scope.consultMailEmail == "" || $scope.consultMailEmail == undefined || $scope.consultMailBody == "" || $scope.consultMailBody == undefined || invalidMail) {
            return;
        }

        $window.grecaptcha.execute(recaptchaConsult);
        */
        if ($scope.consultEmailPhonenumber == "" || $scope.consultEmailPhonenumber == undefined) { 
            $scope.shakingInput["cMailPhoneField"]();
            $scope.shakingInput["bMailPhoneField"]();
            return; 
        }
        $scope.sendEmailConsult("notoken");
    }

    $scope.sendEmailConsult = function(token) {
        // if (token == undefined || token == null || token == "") {
        //     $("#modal-consult").modal("hide");
        //     myLib.openModal(null, "mail-error");
        //     return;
        // }
        
        $scope.sendMailDisabled = true;
        EmailService.sendPhonenumber($scope.consultEmailPhonenumber, function(isSuccess) {
            if (isSuccess) {
                myLib.openModal(null, "phone-sent");
                $scope.consultEmailPhonenumber = "";
            } else {
                myLib.openModal(null, "mail-error");
            }
            $window.grecaptcha.reset(recaptchaConsult);
            $scope.sendMailDisabled = false;
        });
        $("#modal-consult").modal("hide");
    }
    $window.sendEmailConsult = $scope.sendEmailConsult;

    $scope.loadingBuy = {}; 
    $scope.buy = function($event){ 
        OrderService.buy($scope, $event);
    };

    $scope.applyPromocode = function($event) {
        var enteredPromo = $scope.order.form.Promocode;
        if (enteredPromo != undefined && enteredPromo != null && enteredPromo != "") {
            $scope.order.form.promoLoading = true;
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    $scope.order.form.promoLoading = false;
                    var discount = parseFloat(this.responseText);
                    if (discount > 0) {
                        $scope.order.appliedPromocodeDiscount = discount;
                    } else if (discount < 0) {
                        $scope.shakingInput["custPromo"]();
                        $scope.shakingInput["custPromoMobile"]();
                    }
                    $scope.$apply();
                }
            };
            xmlhttp.open("GET", "promocodes.php?code=" + enteredPromo, true);
            xmlhttp.send();
        }
    };
    // $scope.buyTest = function($event){ OrderService.buyTest($scope, $event) };

    $scope.yandexLinkClick = function(name) {
        if (yaCounter45087543 == undefined) { return; }
        switch(name){
            case 'Play':
                yaCounter45087543.reachGoal('downloadPM');
                break;
            case 'App Store':
                yaCounter45087543.reachGoal('downloadAS');
                break;
            case 'test-drive':
                yaCounter45087543.reachGoal('testDrive');
                break;
        }
    }

    $scope.yandexClientDataFromForm = function(){
        if (yaCounter45087543 == undefined) { return; }
        yaCounter45087543.reachGoal('userData', $scope.order.form);
    }
    $scope.yandexBueBannerGoal = function(){
        if (yaCounter45087543 != undefined) {
            yaCounter45087543.reachGoal('bannerBuy');
        }
        $('html, body').animate({
            scrollTop: $('#buy-block').offset().top
        }, 1000);
    }

    mapService.setCities($scope);
    
    $scope.showInfoCity = {
        selectedCity:null,
        show:false
    }

}]);