angular.module("App")
.directive("logoSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.5 198.52">
                    <g>
                        <g>
                        <path d="M136.23,60A39.07,39.07,0,0,0,108.91,71l-.84.82V0L96.95,10.76V82.62l-18.4,17.81V99.26a39.28,39.28,0,1,0-12,28.22h0l.84-.82v71.86h0l11.12-10.76V115.9l18.4-17.81v1.17A39.28,39.28,0,1,0,136.23,60ZM67.44,111.19l-8.57,8.3h0a28.16,28.16,0,1,1,8.57-20.23Zm68.79,16.23a28.19,28.19,0,0,1-28.16-28.16V87.33l8.57-8.3a28.16,28.16,0,1,1,19.59,48.39Z" style="fill: url(#logo-linear-gradient)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("dropSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 44.52 67.21">
                    <g>
                        <g>
                        <path d="M22.26,1.78S1,33.2,1,44.94a21.26,21.26,0,1,0,42.52,0C43.52,33.2,22.26,1.78,22.26,1.78ZM14.47,53.17a5.26,5.26,0,0,1-5.26-5.26c0-2.91,5.26-10.68,5.26-10.68S19.74,45,19.74,47.91A5.26,5.26,0,0,1,14.47,53.17Z" style="fill: none;stroke-miterlimit: 10;stroke-width: 2px;stroke: url(#drop-linear-gradient)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("rubleSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 46.79 61">
                    <g>
                        <g>
                            <path d="M24.48,2c6.61,0,11.69,1.67,15.1,4.6S44.79,14,44.79,19.49a16.91,16.91,0,0,1-1.44,7A15.94,15.94,0,0,1,39.26,32a19,19,0,0,1-6.41,3.62A26,26,0,0,1,24.48,37H15v7H41a1,1,0,0,1,1,1V48a1,1,0,0,1-1,1H15V59H9V49H3a1,1,0,0,1-1-1V45a1,1,0,0,1,1-1H9V37H3a1,1,0,0,1-1-1V33a1,1,0,0,1,1-1H9V3.53A1.54,1.54,0,0,1,10.53,2h14M15,32h9.48a18.47,18.47,0,0,0,6.14-1.06,13.6,13.6,0,0,0,4.54-2.71A11.34,11.34,0,0,0,38,24.34a12.35,12.35,0,0,0,1-4.94c0-4-1.16-7-3.55-9.08S29.35,7,24.48,7H15V32M24.48,0h-14A3.54,3.54,0,0,0,7,3.53V30H3a3,3,0,0,0-3,3V36a3,3,0,0,0,3,3H7v3H3a3,3,0,0,0-3,3V48a3,3,0,0,0,3,3H7V61H17V51H41a3,3,0,0,0,3-3V45a3,3,0,0,0-3-3H17V39h7.48a27.94,27.94,0,0,0,9-1.47,20.94,20.94,0,0,0,7.08-4,17.88,17.88,0,0,0,4.61-6.23,18.87,18.87,0,0,0,1.61-7.81c0-6.1-2-11.08-5.9-14.4S31.36,0,24.48,0ZM17,9h7.48c4.2,0,7.52,1,9.62,2.82C36,13.52,37,16,37,19.4a10.41,10.41,0,0,1-.82,4.14,9.31,9.31,0,0,1-2.32,3.2A11.51,11.51,0,0,1,30,29.05a16.47,16.47,0,0,1-5.48.95H17V9Z" style="fill: url(#ruble-linear-gradient)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("closeSvg", [function() {
    return {
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <g>
          <g>
            <path d="M9.35,7.5l5.41-5.41a.82.82,0,0,0,0-1.15l-.7-.7a.81.81,0,0,0-1.15,0L7.5,5.65,2.09.24A.81.81,0,0,0,.94.24l-.7.7a.82.82,0,0,0,0,1.15L5.65,7.5.24,12.91a.81.81,0,0,0,0,1.15l.7.7a.81.81,0,0,0,1.15,0L7.5,9.35l5.41,5.41a.81.81,0,0,0,1.15,0l.7-.7a.81.81,0,0,0,0-1.15Z" />
          </g>
        </g>
      </svg>
      `
    }
}])

.directive("controlSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 61.5 69.22">
                    <g>
                        <g>
                            <path d="M59.5,2V20.12C59.29,53.33,30.75,67,30.75,67S2.22,53.33,2,20.12V2H59.5m2-2H0V20.12A53.91,53.91,0,0,0,15,57.39,56.12,56.12,0,0,0,29.89,68.8l.86.41.86-.41A56.12,56.12,0,0,0,46.5,57.39a53.9,53.9,0,0,0,15-37.26V0Z" style="fill: url(#control-linear-gradient)"/>
                            <path d="M47,22.2,44.83,20a1.14,1.14,0,0,0-1.61,0L27.3,35.9l-7.44-7.44a1.12,1.12,0,0,0-1.58,0L16,30.71a1.12,1.12,0,0,0,0,1.58L26.51,42.77a1.12,1.12,0,0,0,1.58,0l.17-.17,2.08-2.08L47,23.81A1.14,1.14,0,0,0,47,22.2Z" style="fill: none;stroke-miterlimit: 10;stroke-width: 2px;stroke: url(#control-linear-gradient-2)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("internetSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 53.83 63">
                    <g>
                        <g>
                            <path d="M33,5.75A4.75,4.75,0,0,0,28.25,1H5.75A4.75,4.75,0,0,0,1,5.75v51.5A4.75,4.75,0,0,0,5.75,62h22.5A4.75,4.75,0,0,0,33,57.25ZM14.52,5H19.8m0,0H14.52M33,52H1V9H33Z" style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 2px;stroke: url(#internet-linear-gradient)"/>
                            <circle cx="17.16" cy="56.69" r="2.51" style="fill: url(#internet-linear-gradient-2)"/>
                            <path d="M30.15,11C17.07,11,6.46,18.85,6.46,28.57c0,5.65,3.58,10.67,9.15,13.89L14.2,50.61l10.23-5a31.54,31.54,0,0,0,5.72.52c13.08,0,23.68-7.88,23.68-17.61S43.23,11,30.15,11Z" style="fill: #fff"/>
                            <path d="M29.7,15.94c-9.55,0-17.29,5.76-17.29,12.85,0,4.12,2.62,7.79,6.68,10.14l-1,5.95,7.47-3.61a23,23,0,0,0,4.17.38C39.25,41.65,47,35.9,47,28.8S39.25,15.94,29.7,15.94Z" style="fill: #fff;stroke-miterlimit: 10;stroke-width: 2.56364470118535px;stroke: url(#internet-linear-gradient-3)"/>
                            <circle cx="22.15" cy="28.88" r="2.03" style="fill: url(#internet-linear-gradient-4)"/>
                            <circle cx="29.7" cy="28.88" r="2.03" style="fill: url(#internet-linear-gradient-5)"/>
                            <circle cx="37.26" cy="28.88" r="2.03" style="fill: url(#internet-linear-gradient-6)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("batarySvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 36.63 66">
                    <g>
                        <g>
                             <rect x="4.14" y="33.46" width="28.34" height="24.48" transform="translate(-27.38 64.01) rotate(-90)" style="fill: url(#batary-linear-gradient)"/>
                            <path d="M31.16,6.91h-5V3.23A2.23,2.23,0,0,0,23.91,1H12.72a2.23,2.23,0,0,0-2.23,2.23V6.91h-5A4.47,4.47,0,0,0,1,11.38V60.53A4.47,4.47,0,0,0,5.47,65h25.7a4.47,4.47,0,0,0,4.47-4.47V11.38A4.47,4.47,0,0,0,31.16,6.91Z" style="fill: none;stroke-miterlimit: 10;stroke-width: 2px;stroke: url(#batary-linear-gradient-2)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("armchairSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 66.57 60.67">
                    <g>
                        <g>
                            <path d="M10.16,24.56V6.72A5.72,5.72,0,0,1,15.88,1H50.69a5.72,5.72,0,0,1,5.72,5.72V24.56m-11.13,29v4a2.11,2.11,0,0,0,2.11,2.11h3.4a2.11,2.11,0,0,0,2.11-2.11v-4m-39.24,0v4a2.11,2.11,0,0,0,2.11,2.11h3.4a2.11,2.11,0,0,0,2.11-2.11v-4M52.91,41.92V33.39a5.33,5.33,0,0,0-5.33-5.33H19a5.33,5.33,0,0,0-5.33,5.33v8.53ZM13.67,42V30.46a6.33,6.33,0,0,0-6.33-6.33h0A6.33,6.33,0,0,0,1,30.46v14.2m64.57,0V30.46a6.33,6.33,0,0,0-6.33-6.33h0a6.33,6.33,0,0,0-6.33,6.33V42M1,44.66H1A8.36,8.36,0,0,0,9.36,53H57.21a8.36,8.36,0,0,0,8.36-8.36h0" style="fill: none;stroke-miterlimit: 10;stroke-width: 2px;stroke: url(#armchair-linear-gradient)"/>
                        </g>
                    </g>
                </svg>`
    }
}])

.directive("lightSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M30,2.34,57.66,30,30,57.66,2.34,30,30,2.34M30,0a1.17,1.17,0,0,0-.83.34L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83a1.17,1.17,0,0,0,0-1.66L30.83.34A1.17,1.17,0,0,0,30,0Z" style="fill: #73e49d"/>
        <g>
          <path d="M30.18,26a4.66,4.66,0,1,0,4.66,4.66A4.66,4.66,0,0,0,30.18,26Zm9.26,4.66" style="fill: #73e49d"/>
          <g>
            <path d="M30,23.36a.84.84,0,0,0,.84-.84V20.58a.84.84,0,1,0-1.67,0v1.94a.84.84,0,0,0,.84.84Zm0,18.17a.84.84,0,0,0,.84-.84V38.74a.84.84,0,1,0-1.67,0v1.94a.84.84,0,0,0,.84.84Zm0-18.17" style="fill: #73e49d"/>
            <path d="M24.52,24.57a.84.84,0,0,0,1.35-1L24.73,22a.84.84,0,0,0-1.35,1Zm0,0" style="fill: #73e49d"/>
            <g>
              <path d="M35.48,36.82a.84.84,0,1,0-1.35,1l1.14,1.57a.84.84,0,1,0,1.35-1Zm0,0" style="fill: #73e49d"/>
              <path d="M22.48,27.37l-1.85-.6a.84.84,0,0,0-.52,1.59L22,29a.84.84,0,0,0,.52-1.59Zm0,0" style="fill: #73e49d"/>
              <path d="M39.88,33,38,32.43A.84.84,0,1,0,37.52,34l1.85.6A.84.84,0,1,0,39.88,33Zm0,0" style="fill: #73e49d"/>
              <path d="M22,32.43l-1.85.6a.84.84,0,1,0,.52,1.59l1.85-.6A.84.84,0,1,0,22,32.43Zm0,0" style="fill: #73e49d"/>
              <path d="M37.78,29A.84.84,0,0,0,38,29l1.85-.6a.84.84,0,1,0-.52-1.59l-1.85.6A.84.84,0,0,0,37.78,29Zm0,0" style="fill: #73e49d"/>
            </g>
            <path d="M24.52,36.82l-1.14,1.57a.84.84,0,0,0,1.35,1l1.14-1.57a.84.84,0,0,0-1.35-1Zm0,0" style="fill: #73e49d"/>
            <path d="M35.27,22l-1.14,1.57a.84.84,0,0,0,1.35,1L36.62,23a.84.84,0,0,0-1.35-1Zm0,0" style="fill: #73e49d"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>`
    }
}])

.directive("moveSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M59.66,29.17,30.83.34a1.17,1.17,0,0,0-1.66,0L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83A1.17,1.17,0,0,0,59.66,29.17ZM30,57.66,2.34,30,30,2.34,57.66,30Z" style="fill: #73e49d;fill-rule: evenodd"/>
        <circle cx="33.36" cy="22.72" r="2.27" style="fill: #73e49d"/>
        <path d="M32.91,25.92l-.06-.06a.79.79,0,0,0-.5-.42l-.54-.14-.07,0-3.87-1a.78.78,0,0,0-.29,0,.8.8,0,0,0-.34.07l-4.44,2.06a.81.81,0,1,0,.68,1.48l4.21-2,1.6.42-.08.13L27,31.34a1.45,1.45,0,0,0-.07.22l-2.74,4.59-4.58,1.53A1.17,1.17,0,1,0,21,39.57L25.67,38a1.07,1.07,0,0,0,.33-.38,1.09,1.09,0,0,0,.17-.19l1.63-2.74,2.9,2.47-3.1,3.49a1.17,1.17,0,0,0,1.75,1.55l3.87-4.36a1.12,1.12,0,0,0,.24-.45,1,1,0,0,0,0-.27,1,1,0,0,0,0-.13,1.14,1.14,0,0,0-.4-.85l-2.67-2.28a2.09,2.09,0,0,0,.47-.66l1.73-3.74.21-.54,1.06,1.7a.78.78,0,0,0,.58.35h0a.77.77,0,0,0,.24,0h0L39,29.68a.78.78,0,0,0,.53-1l0-.07a.78.78,0,0,0-1-.53l-3.61,1.05Z" style="fill: #73e49d;fill-rule: evenodd"/>
      </g>
    </g>
  </g>
</svg>`
    }
}])

.directive("couplSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53">
  <g>
    <g>
      <g>
        <path d="M26.5,2A24.5,24.5,0,1,1,2,26.5,24.53,24.53,0,0,1,26.5,2m0-2A26.5,26.5,0,1,0,53,26.5,26.5,26.5,0,0,0,26.5,0Z" style="fill: #73e49d"/>
        <path d="M35.09,25.76v.58H30l5-7.48a.72.72,0,0,0-.21-1l-2-1.24a.72.72,0,0,0-1,.24l-5,8.45-.28,0A3.6,3.6,0,0,0,24,26.34h-6v-.58H15.32V32h2.59v-.58h6a3.61,3.61,0,0,0,5.12,0h6V32h2.59V25.76Z" style="fill: #73e49d"/>
      </g>
    </g>
  </g>
</svg>`
    }
}])

.directive("forkSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53">
  <g>
    <g>
      <g>
        <path d="M26.5,2A24.5,24.5,0,1,1,2,26.5,24.53,24.53,0,0,1,26.5,2m0-2A26.5,26.5,0,1,0,53,26.5,26.5,26.5,0,0,0,26.5,0Z" style="fill: #0cc9fa"/>
        <g>
          <g>
            <rect x="22.33" y="16" width="2.02" height="10.5" rx="0.57" ry="0.57" style="fill: #0cc9fa"/>
            <rect x="28.65" y="16" width="2.02" height="10.5" rx="0.57" ry="0.57" style="fill: #0cc9fa"/>
          </g>
          <rect x="25.49" y="26.5" width="2.02" height="10.5" rx="0.57" ry="0.57" style="fill: #0cc9fa"/>
          <path d="M32.84,24.65l-5.36,5.51a1.14,1.14,0,0,1-2,0l-5.36-5.51a1.14,1.14,0,0,1,1-1.71H31.86A1.14,1.14,0,0,1,32.84,24.65Z" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>`
    }
}])

.directive("eyeSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M30,2.34,57.66,30,30,57.66,2.34,30,30,2.34M30,0a1.17,1.17,0,0,0-.83.34L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83a1.17,1.17,0,0,0,0-1.66L30.83.34A1.17,1.17,0,0,0,30,0Z" style="fill: #0cc9fa"/>
        <g>
          <path d="M30,23.58a11.84,11.84,0,0,0-10.74,6.85.46.46,0,0,0,0,.39,11.85,11.85,0,0,0,21.49,0,.46.46,0,0,0,0-.39A11.84,11.84,0,0,0,30,23.58ZM30,35.5a4.86,4.86,0,1,1,4.86-4.86A4.86,4.86,0,0,1,30,35.5Zm0,0" style="fill: #0cc9fa"/>
          <path d="M33.11,30.63A3.11,3.11,0,1,1,30,27.52a3.11,3.11,0,0,1,3.11,3.11Zm0,0" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>`
    }
}])

.directive("leakSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M30,2.34,57.66,30,30,57.66,2.34,30,30,2.34M30,0a1.17,1.17,0,0,0-.83.34L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83a1.17,1.17,0,0,0,0-1.66L30.83.34A1.17,1.17,0,0,0,30,0Z" style="fill: #0cc9fa"/>
        <g>
          <path d="M27.46,39.4a2.54,2.54,0,0,0,5.09,0c0-1.4-2.54-5.16-2.54-5.16S27.46,38,27.46,39.4Zm1.61,1a.63.63,0,0,1-.63-.63,4.31,4.31,0,0,1,.63-1.28,4.31,4.31,0,0,1,.63,1.28A.63.63,0,0,1,29.07,40.39Z" style="fill: #0cc9fa"/>
          <polygon points="29.21 32.3 29.21 25.32 26.62 25.32 26.62 26 19.9 26 19.9 31.62 26.62 31.62 26.62 32.3 29.21 32.3" style="fill: #0cc9fa"/>
          <polygon points="40.1 26 33.38 26 33.38 25.32 30.79 25.32 30.79 32.3 33.38 32.3 33.38 31.62 40.1 31.62 40.1 26" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("aquaSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <g>
    <g>
      <g>
        <path d="M25,12.81S17,24.7,17,29.14a8,8,0,1,0,16.09,0C33,24.7,25,12.81,25,12.81ZM22.05,32.26a2,2,0,0,1-2-2c0-1.1,2-4,2-4s2,2.94,2,4A2,2,0,0,1,22.05,32.26Z" style="fill: #73e49d"/>
        <path d="M48,2V48H2V2H48m.64-2H1.36A1.36,1.36,0,0,0,0,1.36V48.64A1.36,1.36,0,0,0,1.36,50H48.64A1.36,1.36,0,0,0,50,48.64V1.36A1.36,1.36,0,0,0,48.64,0Z" style="fill: #73e49d"/>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("lampSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53">
  <g>
    <g>
      <g>
        <path d="M26.5,2A24.5,24.5,0,1,1,2,26.5,24.53,24.53,0,0,1,26.5,2m0-2A26.5,26.5,0,1,0,53,26.5,26.5,26.5,0,0,0,26.5,0Z" style="fill: #73e49d"/>
        <g>
          <path d="M29.48,33.84a.66.66,0,0,0-.66-.66H24.14a.66.66,0,0,0-.66.66.65.65,0,0,0,.35.57v1.08A1.53,1.53,0,0,0,25.28,37h2.41a1.53,1.53,0,0,0,1.44-1.52V34.41A.65.65,0,0,0,29.48,33.84Z" style="fill: #73e49d"/>
          <path d="M31.75,25.8a6.32,6.32,0,1,0-10.54,0l0,0,2.32,3.43h0v2h5.8v-2h0l2.35-3.42Z" style="fill: #73e49d"/>
        </g>
      </g>
    </g>
  </g>
</svg>`
    }
}])

.directive("wifiSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <g>
    <g>
      <g>
        <path d="M48,2V48H2V2H48m.64-2H1.36A1.36,1.36,0,0,0,0,1.36V48.64A1.36,1.36,0,0,0,1.36,50H48.64A1.36,1.36,0,0,0,50,48.64V1.36A1.36,1.36,0,0,0,48.64,0Z" style="fill: #73e49d"/>
        <g>
          <path d="M36.29,20.69a16,16,0,0,0-22.58,0,1.4,1.4,0,0,0,0,2,1.38,1.38,0,0,0,1,.4,1.41,1.41,0,0,0,1-.41,13.2,13.2,0,0,1,18.64,0,1.39,1.39,0,1,0,2-2Z" style="fill: #73e49d"/>
          <path d="M25,23.06h0a8.87,8.87,0,0,0-6.31,2.61,1.4,1.4,0,0,0,0,2,1.38,1.38,0,0,0,1,.4,1.4,1.4,0,0,0,1-.41,6.14,6.14,0,0,1,8.68,0,1.39,1.39,0,0,0,2-2A8.85,8.85,0,0,0,25,23.06Z" style="fill: #73e49d"/>
          <circle cx="25" cy="31.98" r="2" style="fill: #73e49d"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("lightningSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <g>
    <g>
      <g>
        <path d="M48,2V48H2V2H48m.64-2H1.36A1.36,1.36,0,0,0,0,1.36V48.64A1.36,1.36,0,0,0,1.36,50H48.64A1.36,1.36,0,0,0,50,48.64V1.36A1.36,1.36,0,0,0,48.64,0Z" style="fill: #0cc9fa"/>
        <g>
          <polygon points="19.27 26.82 28.95 11.29 26.01 26.82 19.27 26.82" style="fill: #0cc9fa"/>
          <polygon points="30.73 23.18 21.05 38.7 23.99 23.18 30.73 23.18" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("verticalWaveSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <g>
    <g>
      <g>
        <path d="M48,2V48H2V2H48m.64-2H1.36A1.36,1.36,0,0,0,0,1.36V48.64A1.36,1.36,0,0,0,1.36,50H48.64A1.36,1.36,0,0,0,50,48.64V1.36A1.36,1.36,0,0,0,48.64,0Z" style="fill: #0cc9fa"/>
        <g>
          <path d="M31.23,36.5a1,1,0,0,1-.72-1.73c.41-.41,3.9-4.17,0-8.07a7.38,7.38,0,0,1-2.41-5.29,8.71,8.71,0,0,1,2.41-5.61,1,1,0,0,1,1.43,1.44c-.41.42-3.89,4.14,0,8,4.32,4.33,1.89,9,0,10.94A1,1,0,0,1,31.23,36.5Z" style="fill: #0cc9fa"/>
          <path d="M25,36.5a1,1,0,0,1-.72-1.73c.41-.41,3.9-4.17,0-8.07a7.38,7.38,0,0,1-2.41-5.29,8.71,8.71,0,0,1,2.41-5.61,1,1,0,0,1,1.43,1.44h0c-.41.41-3.89,4.14,0,8,4.32,4.33,1.89,9,0,10.94A1,1,0,0,1,25,36.5Z" style="fill: #0cc9fa"/>
          <path d="M18.77,36.5a1,1,0,0,1-.72-1.73c.41-.41,3.9-4.17,0-8.07a7.38,7.38,0,0,1-2.41-5.29,8.71,8.71,0,0,1,2.41-5.61,1,1,0,0,1,1.43,1.44c-.41.42-3.89,4.14,0,8,4.32,4.33,1.89,9,0,10.94A1,1,0,0,1,18.77,36.5Z" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("littleLightSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <g>
    <g>
      <g>
        <path d="M48,2V48H2V2H48m.64-2H1.36A1.36,1.36,0,0,0,0,1.36V48.64A1.36,1.36,0,0,0,1.36,50H48.64A1.36,1.36,0,0,0,50,48.64V1.36A1.36,1.36,0,0,0,48.64,0Z" style="fill: #0cc9fa"/>
        <path d="M25,12.09a46.69,46.69,0,0,1-2.91,8.83c-1.47,3.3-2.55,5.06-2.69,7.18s.51,7,3.06,9.81a13.23,13.23,0,0,1,.85-7,30.42,30.42,0,0,0,1.86-4.77c0,.15,1.13,2.38,1.79,4.21a11.3,11.3,0,0,1-.24,7.53,14.18,14.18,0,0,0,3.85-8.15c.5-7.6-3.92-16.44-5.57-17.67Zm0,0" style="fill: #0cc9fa"/>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("brokenLineSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M30,2.34,57.66,30,30,57.66,2.34,30,30,2.34M30,0a1.17,1.17,0,0,0-.83.34L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83a1.17,1.17,0,0,0,0-1.66L30.83.34A1.17,1.17,0,0,0,30,0Z" style="fill: #73e49d"/>
        <g>
          <path d="M21.8,31.68a1,1,0,0,0,.73-.3l4.95-5,4.9,4.9a1,1,0,0,0,1.46,0l5.08-5.08a1,1,0,0,0-1.46-1.46l-4.35,4.35-4.9-4.9a1,1,0,0,0-1.46,0l-5.68,5.68a1,1,0,0,0,.73,1.76Z" style="fill: #73e49d"/>
          <path d="M37.47,30.44l-4.35,4.35-4.9-4.9a1,1,0,0,0-1.46,0l-5.68,5.68A1,1,0,1,0,22.53,37l4.95-4.95,4.9,4.9a1,1,0,0,0,1.46,0l5.08-5.08a1,1,0,0,0-1.46-1.46Z" style="fill: #73e49d"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("temperatureSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M30,2.34,57.66,30,30,57.66,2.34,30,30,2.34M30,0a1.17,1.17,0,0,0-.83.34L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83a1.17,1.17,0,0,0,0-1.66L30.83.34A1.17,1.17,0,0,0,30,0Z" style="fill: #73e49d"/>
        <g>
          <path d="M31.14,33.47V28.28A1.08,1.08,0,0,0,30,27.14a1.14,1.14,0,0,0-1.14,1.14v5.18a3.32,3.32,0,0,0-2.28,3.19,3.43,3.43,0,1,0,4.56-3.19Zm0,0" style="fill: #73e49d"/>
          <path d="M33.43,32.11V22.31A3.37,3.37,0,0,0,30,18.89a3.44,3.44,0,0,0-3.43,3.43V32.1a5.78,5.78,0,0,0-2.28,4.57,5.71,5.71,0,1,0,11.42,0,5.78,5.78,0,0,0-2.28-4.56ZM30,41.24a4.59,4.59,0,0,1-2.29-8.56V22.31a2.28,2.28,0,1,1,4.56,0V32.67A4.6,4.6,0,0,1,30,41.24Zm0,0" style="fill: #73e49d"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("ventilSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53">
  <g>
    <g>
      <g>
        <path d="M26.5,2A24.5,24.5,0,1,1,2,26.5,24.53,24.53,0,0,1,26.5,2m0-2A26.5,26.5,0,1,0,53,26.5,26.5,26.5,0,0,0,26.5,0Z" style="fill: #73e49d"/>
        <polygon points="30.23 25.88 30.23 24.02 35.52 24.02 35.52 19.26 29.13 20.36 27.92 19.52 27.83 19.52 27.83 18.5 25.29 18.5 25.29 19.52 25.2 19.52 23.98 20.36 17.48 19.26 17.48 24.02 22.88 24.02 22.88 25.88 24.01 25.88 24.01 28.47 22.55 28.47 22.55 27.85 18.77 27.85 18.77 28.47 18.77 33.88 18.77 34.5 22.55 34.5 22.55 33.88 30.57 33.88 30.57 34.5 34.35 34.5 34.35 33.88 34.35 28.47 34.35 27.85 30.57 27.85 30.57 28.47 29.1 28.47 29.1 25.88 30.23 25.88" style="fill: #73e49d"/>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("lockSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53">
  <g>
    <g>
      <g>
        <path d="M26.5,2A24.5,24.5,0,1,1,2,26.5,24.53,24.53,0,0,1,26.5,2m0-2A26.5,26.5,0,1,0,53,26.5,26.5,26.5,0,0,0,26.5,0Z" style="fill: #0cc9fa"/>
        <g>
          <polygon points="22.23 35.5 26.5 18.79 30.77 35.5 22.23 35.5" style="fill: #0cc9fa"/>
          <circle cx="26.5" cy="22.12" r="4.62" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("hallucinationSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59">
  <g>
    <g>
      <g>
        <path d="M29.5,2.35,56.65,29.5,29.5,56.65,2.35,29.5,29.5,2.35M29.5,0a1.15,1.15,0,0,0-.81.34L.34,28.69a1.15,1.15,0,0,0,0,1.63L28.69,58.66a1.15,1.15,0,0,0,1.63,0L58.66,30.31a1.15,1.15,0,0,0,0-1.63L30.31.34A1.15,1.15,0,0,0,29.5,0Z" style="fill: #0cc9fa"/>
        <path d="M29.5,37.7a8.06,8.06,0,1,1,8.06-8.06A8.07,8.07,0,0,1,29.5,37.7Zm0-14.46a6.4,6.4,0,1,0,6.39,6.4A6.4,6.4,0,0,0,29.5,23.24Z" style="fill: #0cc9fa"/>
        <path d="M29.5,33.45a3.82,3.82,0,1,1,3.82-3.82A3.82,3.82,0,0,1,29.5,33.45Zm0-6a2.15,2.15,0,1,0,2.15,2.15A2.15,2.15,0,0,0,29.5,27.48Z" style="fill: #0cc9fa"/>
        <path d="M29.5,42.21A12.58,12.58,0,1,1,42.08,29.63,12.6,12.6,0,0,1,29.5,42.21Zm0-23.49A10.91,10.91,0,1,0,40.41,29.63,10.92,10.92,0,0,0,29.5,18.72Z" style="fill: #0cc9fa"/>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("bigFlameSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <g>
    <g>
      <g>
        <path d="M30,2.34,57.66,30,30,57.66,2.34,30,30,2.34M30,0a1.17,1.17,0,0,0-.83.34L.34,29.17a1.17,1.17,0,0,0,0,1.66L29.17,59.66a1.17,1.17,0,0,0,1.66,0L59.66,30.83a1.17,1.17,0,0,0,0-1.66L30.83.34A1.17,1.17,0,0,0,30,0Z" style="fill: #0cc9fa"/>
        <g>
          <path d="M31.72,18.89s-4.29,2-4.94,9c-.53,5.79,5,10.44,4.95,14.48,3.59-6.56.31-11.85-.47-14.31C29.35,22.12,31.72,18.89,31.72,18.89Z" style="fill: #0cc9fa"/>
          <path d="M24.89,26.57s-2.59,1.19-3,5.44c-.32,3.5,3,6.3,3,8.74,2.17-4,.19-7.15-.29-8.64C23.46,28.52,24.89,26.57,24.89,26.57Z" style="fill: #0cc9fa"/>
          <path d="M37.12,21.28s-2.59,1.19-3,5.44c-.32,3.5,3,6.3,3,8.74,2.17-4,.19-7.15-.29-8.64C35.69,23.23,37.12,21.28,37.12,21.28Z" style="fill: #0cc9fa"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("playmarketSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.67 20.86">
  <g>
    <g>
      <path d="M17.81,8.94,2.56.23A1.71,1.71,0,0,0,1.71,0H1.4a1.68,1.68,0,0,0-.55.2A1.71,1.71,0,0,0,0,1.71V19.14a1.71,1.71,0,0,0,1.4,1.68h.31a1.72,1.72,0,0,0,.85-.22l15.25-8.72a1.71,1.71,0,0,0,.86-1.48h0a1.71,1.71,0,0,0-.86-1.49Zm-6-1.73-1.52,2L6.41,4.14Zm5.29,3.41L6.41,16.72,13.08,8l4,2.28a.22.22,0,0,1,0,.39Zm0,0"/>
    </g>
  </g>
</svg>
`
    }
}])

.directive("qbBlackSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29.41">
        <g>
          <g>
            <path d="M20.18,8.89a5.79,5.79,0,0,0-4,1.64l-.12.12V0L14.36,1.59V12.24l-2.73,2.64v-.17a5.82,5.82,0,1,0-1.77,4.18h0l.12-.12V29.41h0l1.65-1.59V17.17l2.73-2.64v.17a5.82,5.82,0,1,0,5.82-5.82ZM10,16.47,8.72,17.7h0a4.17,4.17,0,1,1,1.27-3Zm10.19,2.4A4.18,4.18,0,0,1,16,14.71V12.94l1.27-1.23a4.17,4.17,0,1,1,2.9,7.17Z" />
          </g>
        </g>
      </svg>
      `
    }
}])

.directive("appleSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.38 25.03">
  <g>
    <g>
      <g>
        <path d="M17,13.3a5.67,5.67,0,0,1,2.7-4.77,5.85,5.85,0,0,0-4.58-2.48c-1.95-.2-3.8,1.15-4.79,1.15S7.84,6.08,6.23,6.11A6.13,6.13,0,0,0,1,9.25c-2.21,3.83-.57,9.5,1.59,12.61,1.05,1.52,2.31,3.23,4,3.17s2.18-1,4.1-1,2.46,1,4.13,1,2.79-1.55,3.83-3.07a13.84,13.84,0,0,0,1.73-3.56A5.53,5.53,0,0,1,17,13.3Z" />
        <path d="M13.87,4a5.51,5.51,0,0,0,1.3-4,5.61,5.61,0,0,0-3.69,1.9,5.25,5.25,0,0,0-1.33,3.87A4.64,4.64,0,0,0,13.87,4Z" />
      </g>
    </g>
  </g>
</svg>
`
    }
}])

.directive("radioSvg", [function() {
    return {
        replace:true,
        scope:{
            state:"="
        },
        template:`<svg class="radiobox-theme" ng-class="{active:state}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 19 19">
                        <g>
                            <g>
                                <circle class="outer-radioSvg" cx="9.5" cy="9.5" r="9" style="fill: none;stroke-miterlimit: 10; stroke: url(#radio-linear-gradient)"/>
                                <circle class="inner-radioSvg" cx="9.5" cy="9.5" r="5.1" style="fill: url(#radio-linear-gradient-2)"/>
                            </g>
                        </g>
                    </svg>`
    }
}])

.directive("colorRadioSvg", [function() {
    return {
        replace:true,
        scope:{
            state:"=",
            color:"@"
        },
        template:`<svg class="color-radiobox-theme" ng-class="{active:state}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 21.17 21.17">
  <g>
    <g>
      <circle class="outer-radioSvg" cx="10.58" cy="10.58" r="9.58" style="fill: none;stroke-miterlimit: 10;stroke-width: 2px;stroke: url(#colorRadio-linear-gradient)"/>
      <circle class="inner-radioSvg" cx="10.58" cy="10.58" r="8.37" ng-style="{fill:color}" />
    </g>
  </g>
</svg>
`
    }
}])

.directive("vkSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.08 28.08">
        <g>
          <g>
            <path d="M14,0a14,14,0,1,0,14,14A14,14,0,0,0,14,0Zm5.87,15.09a10,10,0,0,0,.72.73h0l0,0a8.81,8.81,0,0,1,1.69,2l.06.11a1.08,1.08,0,0,1,.06.23.67.67,0,0,1,0,.3.43.43,0,0,1-.22.24,1,1,0,0,1-.52.11l-2.26,0a1,1,0,0,1-.5,0,2.17,2.17,0,0,1-.46-.19l-.18-.11a4.15,4.15,0,0,1-.62-.57q-.35-.38-.61-.69a3,3,0,0,0-.54-.51.56.56,0,0,0-.5-.14l-.07,0a.74.74,0,0,0-.15.13,1.13,1.13,0,0,0-.19.26,1.8,1.8,0,0,0-.15.46,2.94,2.94,0,0,0-.06.69.88.88,0,0,1,0,.24.59.59,0,0,1-.07.16l0,0a.7.7,0,0,1-.47.19h-1a4,4,0,0,1-1.29-.15,4.9,4.9,0,0,1-1.16-.47,10.44,10.44,0,0,1-.91-.58,5.23,5.23,0,0,1-.62-.51l-.22-.21-.24-.27q-.15-.18-.63-.8t-.94-1.33q-.46-.71-1.08-1.87T5.57,10.2A.7.7,0,0,1,5.52,10a.28.28,0,0,1,0-.14l0-.05a.62.62,0,0,1,.5-.17l2.42,0,.2.06.14.08,0,0a.59.59,0,0,1,.21.28c.12.3.25.6.41.92s.27.56.36.72l.14.26a10.34,10.34,0,0,0,.5.92,4.46,4.46,0,0,0,.43.61,2.07,2.07,0,0,0,.37.34.56.56,0,0,0,.3.12.68.68,0,0,0,.24,0l0,0,.11-.19a1.45,1.45,0,0,0,.12-.42q0-.26.08-.72a6.86,6.86,0,0,0,0-1.11,4.2,4.2,0,0,0-.08-.65,1.65,1.65,0,0,0-.12-.41l-.05-.11a1.09,1.09,0,0,0-.75-.38c-.08,0-.06-.08,0-.21a1.2,1.2,0,0,1,.34-.27,5.81,5.81,0,0,1,2.11-.21,5.94,5.94,0,0,1,1.19.12,1,1,0,0,1,.3.12.48.48,0,0,1,.18.21,1.38,1.38,0,0,1,.09.28,2,2,0,0,1,0,.4c0,.17,0,.33,0,.49s0,.36,0,.62,0,.51,0,.73c0,.06,0,.19,0,.37s0,.32,0,.42a3.46,3.46,0,0,0,0,.36,1,1,0,0,0,.1.35.58.58,0,0,0,.2.22l.15,0c.05,0,.13,0,.23-.1a3.23,3.23,0,0,0,.34-.31,4.65,4.65,0,0,0,.46-.59c.18-.27.38-.59.6-1a14.32,14.32,0,0,0,.95-2,.58.58,0,0,1,.09-.15.4.4,0,0,1,.1-.09l0,0,0,0,.12,0H19l2.55,0a1.41,1.41,0,0,1,.57,0,.53.53,0,0,1,.27.15l.05.09q.2.57-1.33,2.6l-.58.75a6.66,6.66,0,0,0-.8,1.16A.68.68,0,0,0,19.91,15.09Z" style="fill: #00c9ff"/>
          </g>
        </g>
      </svg>      
`
    }
}])

.directive("inSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.08 28.08">
        <g>
          <g>
            <g>
              <path d="M14,0a14,14,0,1,0,14,14A14,14,0,0,0,14,0Zm6.74,19.06a1.74,1.74,0,0,1-1.73,1.73H9A1.74,1.74,0,0,1,7.3,19.06V9A1.74,1.74,0,0,1,9,7.3h10A1.74,1.74,0,0,1,20.78,9Z" style="fill: #00c9ff"/>
              <path d="M14.05,16.64A2.66,2.66,0,0,0,16,15.87a2.57,2.57,0,0,0,0-3.71,2.77,2.77,0,0,0-3.83,0,2.57,2.57,0,0,0,0,3.71A2.65,2.65,0,0,0,14.05,16.64Z" style="fill: #00c9ff"/>
              <path d="M18.25,14.16a3.89,3.89,0,0,1-.56,2,4.1,4.1,0,0,1-1.53,1.48,4.22,4.22,0,0,1-2.11.54,4.1,4.1,0,0,1-3-1.19,3.85,3.85,0,0,1-1.23-2.88A3.78,3.78,0,0,1,10,13H8.8V18.7a.53.53,0,0,0,.54.54h9.38a.52.52,0,0,0,.38-.15.51.51,0,0,0,.16-.38V13H18.07A3.78,3.78,0,0,1,18.25,14.16Z" style="fill: #00c9ff"/>
              <path d="M18.65,8.8H17.12a.58.58,0,0,0-.43.18.59.59,0,0,0-.18.43v1.45a.6.6,0,0,0,.61.61h1.53a.6.6,0,0,0,.61-.61V9.41A.59.59,0,0,0,19.08,9,.58.58,0,0,0,18.65,8.8Z" style="fill: #00c9ff"/>
            </g>
          </g>
        </g>
      </svg>           
`
    }
}])

.directive("fbSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.08 28.08">
        <g>
          <g>
            <path d="M14,0a14,14,0,1,0,14,14A14,14,0,0,0,14,0Zm3.64,14.18H15v8H12v-8H10v-3h2.16l0-1.92c0-2.36.67-4,3.46-4H18v3H16.5A1.4,1.4,0,0,0,15,9.68v1.5h3Zm0-.15" style="fill: #00c9ff"/>
          </g>
        </g>
      </svg>               
`
    }
}])

.directive("twSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.08 28.08">
        <g>
          <g>
            <path d="M14,0a14,14,0,1,0,14,14A14.06,14.06,0,0,0,14,0Zm6.27,10.83c0,.14,0,.28,0,.42A9.19,9.19,0,0,1,6.16,19,6.5,6.5,0,0,0,11,17.65a3.24,3.24,0,0,1-3-2.25,3.11,3.11,0,0,0,.61.06,3.19,3.19,0,0,0,.85-.11A3.23,3.23,0,0,1,6.8,12.19a.21.21,0,0,1,0,0,3.19,3.19,0,0,0,1.46.4,3.23,3.23,0,0,1-1-4.31,9.18,9.18,0,0,0,6.66,3.38,3.23,3.23,0,0,1,5.51-2.95,6.39,6.39,0,0,0,2.06-.78,3.25,3.25,0,0,1-1.42,1.79,6.41,6.41,0,0,0,1.86-.51,6.58,6.58,0,0,1-1.61,1.67Zm0,0" style="fill: #00c9ff"/>
          </g>
        </g>
      </svg>                 
`
    }
}])

.directive("emailSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg class="email" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.58 12.57">
        <g>
          <g>
            <g>
              <rect x="0.5" y="0.5" width="15.58" height="11.57" rx="1" ry="1" style="fill: none;stroke: #00c9ff;stroke-miterlimit: 10"/>
              <line x1="1.02" y1="0.99" x2="8.29" y2="7.29" style="fill: none;stroke: #00c9ff;stroke-linecap: round;stroke-linejoin: round"/>
              <line x1="15.56" y1="0.99" x2="8.29" y2="7.29" style="fill: none;stroke: #00c9ff;stroke-linecap: round;stroke-linejoin: round"/>
            </g>
          </g>
        </g>
      </svg>                  
`
    }
}])

.directive("phoneSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg class="phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.55 20.12">
        <g>
          <g>
            <path d="M11.05,2.07A1.57,1.57,0,0,0,9.49.5H2.07A1.57,1.57,0,0,0,.5,2.07v16a1.57,1.57,0,0,0,1.57,1.57H9.49a1.57,1.57,0,0,0,1.57-1.57Zm0,1.07V16.32H.5V3.14m0,0H11.05" style="fill: none;stroke: #00c9ff;stroke-linecap: round;stroke-miterlimit: 10"/>
            <circle cx="5.83" cy="17.98" r="0.83" style="fill: #00c9ff"/>
          </g>
        </g>
      </svg>              
`
    }
}])

.directive("magnifierSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.86 22.91">
        <g>
          <circle cx="13.95" cy="8.91" r="7.41" style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 3px"/>
          <line x1="1.5" y1="21.41" x2="8.65" y2="14.26" style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 3px"/>
        </g>
      </svg>                   
`
    }
}])

.directive("galkaSvg", [function() {
    return {
        replace:true,
        scope:{
            dopClass:"@"
        },
        template:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2 8.8">
        <polyline points="1 1.4 7.4 7.8 14.21 1" style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
      </svg>                        
`
    }
}])








.directive("allGradientsSvg", [function() {
    return {
        scope:{
            dopClass:"@"
        },
        template:`<svg ng-class="dopClass" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <linearGradient id="logo-linear-gradient" y1="99.26" x2="175.5" y2="99.26" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="dot-linear-gradient" x1="15.04" y1="30.09" x2="15.04" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="drop-linear-gradient" x1="22.26" y1="67.21" x2="22.26" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="ruble-linear-gradient" x1="23.4" y1="61" x2="23.4" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="control-linear-gradient" x1="30.75" y1="69.22" x2="30.75" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="control-linear-gradient-2" x1="31.54" y1="44.1" x2="31.54" y2="18.64" xlink:href="#control-linear-gradient"/>
                        <linearGradient id="internet-linear-gradient" x1="17" y1="62.21" x2="17" y2="-0.15" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="internet-linear-gradient-2" x1="17.16" y1="59.2" x2="17.16" y2="54.18" xlink:href="#internet-linear-gradient"/>
                        <linearGradient id="internet-linear-gradient-3" x1="29.7" y1="47.13" x2="29.7" y2="14.66" xlink:href="#internet-linear-gradient"/>
                        <linearGradient id="internet-linear-gradient-4" x1="22.15" y1="30.91" x2="22.15" y2="26.85" xlink:href="#internet-linear-gradient"/>
                        <linearGradient id="internet-linear-gradient-5" x1="29.7" y1="30.91" x2="29.7" y2="26.85" xlink:href="#internet-linear-gradient"/>
                        <linearGradient id="internet-linear-gradient-6" x1="37.26" y1="30.91" x2="37.26" y2="26.85" xlink:href="#internet-linear-gradient"/>
                        <linearGradient id="batary-linear-gradient" x1="18.32" y1="59.87" x2="18.32" y2="31.52" gradientTransform="translate(64.01 27.38) rotate(90)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="batary-linear-gradient-2" x1="18.32" y1="66" x2="18.32" y2="0" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#batary-linear-gradient"/>
                        <linearGradient id="armchair-linear-gradient" x1="33.29" y1="60.67" x2="33.29" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="radio-linear-gradient" x1="9.5" y1="19" x2="9.5" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                        <linearGradient id="radio-linear-gradient-2" x1="9.5" y1="14.6" x2="9.5" y2="4.4" xlink:href="#radio-linear-gradient"/>
                        <linearGradient id="colorRadio-linear-gradient" x1="10.58" y1="21.17" x2="10.58" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#75e59d"/>
                            <stop offset="1" stop-color="#00c9ff"/>
                        </linearGradient>
                    </defs>
                </svg>`
    }
}])